import React, { Component } from "react";

import ReactSlick from "react-slick";
import { MdKeyboardArrowRight } from "react-icons/md";

import "./sass/Slider.scss";

class Slider extends Component {
  constructor() {
    super();
  }

  render() {
    const { children, slidesToShow, dots, className } = this.props;

    const Settings = {
      dots: !dots || dots == false ? false : true,
      arrow: true,
      infinite: false,
      speed: 500,
      slidesToShow: slidesToShow ? slidesToShow : 3,
      slidesToScroll: 1,
      nextArrow: (
        <button>
          <MdKeyboardArrowRight size="35" />
        </button>
      ),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 870,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className={`SliderMentory ${className}`}>
        <ReactSlick {...Settings}>{children}</ReactSlick>
      </div>
    );
  }
}

export default Slider;
