import React, { Component } from "react";

import "./sass/CreateEditListing.scss";

//Components
import Nav from "../../components/Menu";
import Input from "../../UI_Elements/Form/Input";
import Footer from "../../components/Footer";
import RadioButtom from "../../UI_Elements/Form/RadioButton";
import InputTag from "../../UI_Elements/Form/InputTag";
import EditorMentory from "../../UI_Elements/Form/WysiwygEditor";
import MentoryBox from "../../UI_Elements/MentoryBox";
import Location from "../../UI_Elements/Form/Location";

//Assets
import { IoIosArrowDown } from "react-icons/io";
import cameraImg from "../../assets/camera.svg";

import Money from "../../inc/Money";
import classnames from "classnames";
import { Link } from "react-router-dom";

//Response
import Preloader from "../../UI_Elements/Preloader";
import Response from "../../UI_Elements/Response";
import showResponse from "../../UI_Elements/Response/showResponse";

//Axios
import axios from "axios";

//Redux
import { connect } from "react-redux";

//Actions
import uploadImageFunction from "./actions/uploadImage";
import selectServiceFunction from "./actions/selectService";
import changeListingTypeFunction from "./actions/changeListingType";
import pricePerHourFilterFunction from "./actions/pricePerHourFilter";

//Validate
import validateValues from "./actions/validate/validateValues";
import setError from "./actions/validate/setError";
import setFormData from "./actions/validate/setFormData";

import Intercom from "../../inc/Intercom";

class EditListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      services: props.settings.services,
      listingPaid: false,
      pricePerHour: 0,
      userPhoto: null,

      //Errors
      errorListingTitle: "",
      errorLocation: "",
      errorListingBio: "",
      errorCategories: "",
      errorSkills: "",
      errorLanguage: "",
      errorConnectionPreferences: "",
      errorLinkedinProfile: "",
      errorLengthSession: "",
      errorUserPhoto: "",
      errorServices: "",
      errorPricePerHour: "",

      //sent form?
      sentForm: false,
    };

    this.changeListingType = this.changeListingType.bind(this);
    this.pricePerHourFilter = this.pricePerHourFilter.bind(this);
    this.EDIT_LISTING = this.EDIT_LISTING.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.selectService = this.selectService.bind(this);

    this.pricePerHour = React.createRef();
    this.listingTitle = React.createRef();
    this.location = React.createRef();
    this.listingBio = React.createRef();
    this.photoUser = React.createRef();

    this.languages = React.createRef();
    this.connectionPreferences = React.createRef();
    this.linkedinProfile = React.createRef();
  }

  uploadImage = (e) => uploadImageFunction(e, this);
  selectService = (checked, index, value) =>
    selectServiceFunction(checked, index, value, this);
  changeListingType = (value) => changeListingTypeFunction(value, this);
  pricePerHourFilter = (value) => pricePerHourFilterFunction(value, this);

  async EDIT_LISTING(e) {
    e.preventDefault();

    this.setState({ sentForm: true });

    //Validate
    const {
      listingTitleIsValid,
      locationIsValid,
      listingBioIsValid,
      languageIsValid,
      connectionPreferencesIsValid,
      linkedinProfileIsValid,
      servicesIsValid,
      userPhotoIsValid,
      pricePerHourIsValid,
    } = validateValues(this);

    setError(
      {
        listingTitleIsValid,
        locationIsValid,
        listingBioIsValid,
        languageIsValid,
        connectionPreferencesIsValid,
        linkedinProfileIsValid,
        servicesIsValid,
        userPhotoIsValid,
        pricePerHourIsValid,
      },
      {
        listingTitleIsValid: 1,
        locationIsValid: 1,
        listingBioIsValid: 1,
        languageIsValid: 1,
        connectionPreferencesIsValid: 1,
        linkedinProfileIsValid: 0,
        servicesIsValid: 1,
        userPhotoIsValid: 1,
        pricePerHourIsValid: 1,
      },
      this,
    );

    let formData = setFormData(this, { slug: this.props.match.params.slug });

    if (
      listingTitleIsValid &&
      locationIsValid &&
      listingBioIsValid &&
      languageIsValid &&
      connectionPreferencesIsValid &&
      userPhotoIsValid &&
      servicesIsValid &&
      pricePerHourIsValid
    ) {
      await axios
        .post("/api/update-listing", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.setState({ sentForm: false });
          showResponse("listingSuccessResponse");
        })
        .catch((err) => {
          this.setState({ sentForm: false });
          showResponse("listingErrorServerResponse", err.response.data);
        });
    } else {
      this.setState({ sentForm: false });
      showResponse("listingErrorResponse");
    }
  }

  componentDidMount() {
    axios
      .post(`/api/get-listing-to-edit/${this.props.match.params.slug}`)
      .then((res) => {
        this.setState({
          listingFromDB: res.data,
          lengthSessionArray: res.data.sessionLength,
          services: res.data.services,
          listingPaid: res.data.paid,
          pricePerHour: res.data.pricePerHour,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { listingPaid, pricePerHour, listingFromDB, userPhoto, services } =
      this.state;
    //Errors
    const {
      errorListingTitle,
      errorLocation,
      errorListingBio,
      errorCategories,
      errorSkills,
      errorLanguage,
      errorConnectionPreferences,
      errorLinkedinProfile,
      errorUserPhoto,
      errorServices,
      errorPricePerHour,
    } = this.state;

    const { user } = this.props;

    const servicesRedux = this.props.settings.services;

    if (!listingFromDB) return <Preloader page />;

    console.log(this.state);

    return (
      <div id="EditListing">
        <Nav style="stylish" />

        <section className="trlGlobal">
          {/* Responses */}
          <Response
            id="listingSuccessResponse"
            type="success"
            title="Listing updated"
            description="Your listing was successfully updated."
            btnText="go to profile"
            btnAction="/my-listings"
          />
          <Response
            id="listingErrorResponse"
            type="error"
            title="Some values are not valid"
            description="Please review the entered values"
            btnText="Close"
            btnAction="close"
          />
          <Response
            id="listingErrorServerResponse"
            type="error"
            title="Oh no! You can’t do this."
            description=""
            btnText="Close"
            btnAction="close"
          />

          <section id="createAndEditListingContainer">
            <div className="content">
              <h1 className="trl editListing title">Edit listing</h1>
              <p className="trl editListing subtitle">
                Make money by helping others to grow.
              </p>

              <form className="simple smart" onSubmit={this.EDIT_LISTING}>
                <MentoryBox title="Edit listing" className="createListingBox">
                  <div className="uploadPhoto">
                    <div>
                      <label className="trl createListing listingPicture">
                        Listing picture
                      </label>
                      <p className="trl createListing weRecommend">
                        We recommend using a front facing picture.
                      </p>
                      <span
                        style={{
                          backgroundImage: `url("${userPhoto ? userPhoto.url : user.userPhoto}")`,
                        }}
                        onClick={() => this.photoUser.current.click()}
                        className="photo"
                      >
                        <input
                          onChange={(e) => this.uploadImage(e)}
                          ref={this.photoUser}
                          type="file"
                        />
                        {!userPhoto && user.userPhoto.trim() == "" ? (
                          <img src={cameraImg} />
                        ) : null}
                      </span>
                      <span className="error">{errorUserPhoto}</span>
                    </div>
                  </div>
                  <div>
                    <Input
                      value={listingFromDB.title}
                      id="titleEditListing"
                      ref={this.listingTitle}
                      error={errorListingTitle}
                      placeholder="This is how your listing will show in search results."
                      label="Listing title"
                    />
                    <Location
                      value={listingFromDB.location}
                      id="locationEditListing"
                      ref={this.location}
                      error={errorLocation}
                      placeholder="City, Country"
                      label="Where are you based?"
                      ref={this.location}
                    />
                  </div>

                  <div className="EditorMentory">
                    <EditorMentory
                      value={JSON.parse(listingFromDB.description)}
                      ref={this.listingBio}
                      error={errorListingBio}
                      label="What can you help with?"
                    />
                  </div>

                  <div>
                    <InputTag
                      tagsSelected={listingFromDB.languages}
                      ref={this.languages}
                      error={errorLanguage}
                      tags={this.props.settings.languagesName}
                      label="Languages that you are fluent at"
                      icon={<IoIosArrowDown />}
                    />
                    <InputTag
                      tagsSelected={listingFromDB.connectionPreferences}
                      ref={this.connectionPreferences}
                      error={errorConnectionPreferences}
                      tags={this.props.settings.connectionPreferences}
                      label="Connection preferences"
                      icon={<IoIosArrowDown />}
                    />
                  </div>

                  <div style={{ display: "none" }}>
                    <Input
                      value={listingFromDB.linkedinProfile}
                      id="linkedinProfile"
                      ref={this.linkedinProfile}
                      error={errorLinkedinProfile}
                      label="LinkedIn profile"
                    />
                  </div>

                  <br />

                  <div>
                    <RadioButtom
                      checked={listingFromDB.paid ? "0" : "1"}
                      trl
                      type="h"
                      onChange={(value) => this.changeListingType(value)}
                      title="Is this a free or paid listing?"
                      labels={["Paid", "Free"]}
                      values={["Paid", "Free"]}
                    />

                    {listingPaid ? (
                      <div className="paid">
                        <Input
                          value={listingFromDB.pricePerHour}
                          id="pricePerHour"
                          error={errorPricePerHour}
                          validateOnlyNumbers
                          placeholder="50"
                          maxLength="4"
                          onChange={(value) => this.pricePerHourFilter(value)}
                          ref={this.pricePerHour}
                          label="Price per hour"
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="servicesOffered">
                    <div>
                      <p>
                        <span className="trl createListing servicesOffered">
                          Services offered
                        </span>
                      </p>

                      <article className="trlGlobal">
                        {servicesRedux.map((s, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                background: `${i % 2 == 0 ? "#F6F5FA" : "#FFF"}`,
                              }}
                            >
                              <div className="switch">
                                <label>
                                  <input
                                    checked={
                                      services[i].active ? "checked" : ""
                                    }
                                    onChange={(e) =>
                                      this.selectService(e.target.checked, i)
                                    }
                                    type="checkbox"
                                  />
                                  <span className="lever"></span>
                                </label>
                              </div>

                              <p className="service">
                                <span
                                  className={`trl global service title ${s.id}`}
                                >
                                  {s.title}
                                </span>
                                <span
                                  className={`trl global service desc ${s.id}`}
                                >
                                  {s.desc}
                                </span>
                              </p>

                              <div
                                className={classnames({
                                  boxes: true,
                                  disabled: services[i].active ? false : true,
                                })}
                              >
                                <div
                                  className={
                                    services[i].selected
                                      ? services[i].selected.includes(60)
                                        ? "active"
                                        : ""
                                      : ""
                                  }
                                  onClick={() =>
                                    this.selectService(null, i, 60)
                                  }
                                >
                                  <span>
                                    60 min
                                    <br />
                                    {listingPaid ? (
                                      <Money
                                        value={pricePerHour}
                                        currency={this.props.user.currency}
                                      />
                                    ) : (
                                      <span className="trl createListing free">
                                        Free
                                      </span>
                                    )}
                                  </span>
                                </div>
                                <div
                                  className={
                                    services[i].selected
                                      ? services[i].selected.includes(30)
                                        ? "active"
                                        : ""
                                      : ""
                                  }
                                  onClick={() =>
                                    this.selectService(null, i, 30)
                                  }
                                >
                                  <span>
                                    30 min
                                    <br />
                                    {listingPaid ? (
                                      <Money
                                        value={pricePerHour / 2}
                                        currency={this.props.user.currency}
                                      />
                                    ) : (
                                      <span className="trl createListing free">
                                        Free
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </article>
                      <span className="trl editListing errorServices error">
                        {errorServices}
                      </span>
                    </div>
                  </div>
                </MentoryBox>

                <div className="actions flex">
                  {this.state.sentForm ? (
                    <Preloader />
                  ) : (
                    <button
                      type="submit"
                      className="btn trl editListing updateListing"
                    >
                      Update listing
                    </button>
                  )}
                  <p>
                    <Link className="trl editListing cancel" to="/my-listings">
                      cancel
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </section>
        </section>

        <Footer />

        <Intercom user={user} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(EditListing);
