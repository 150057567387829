import React, { Component } from "react";

import "./sass/home.scss";

//Lazy load
import LazyLoad from "react-lazyload";

//Asets
import svgApple from "../../assets/home/logos/apple.svg";
import svgHubspot from "../../assets/home/logos/hubspot.svg";
import svgAirbus from "../../assets/home/logos/airbus.svg";
import svgDekra from "../../assets/home/logos/dekra.svg";

import HowItWorks_1 from "../../assets/home/how_it_works/img1.png";
import HowItWorks_2 from "../../assets/home/how_it_works/img2.png";
import HowItWorks_3 from "../../assets/home/how_it_works/img3.png";

//Components
import CardMentor from "../../components/CardMentor";
import SignUp from "../../components/SignUp";
import Footer from "../../components/Footer";
import Nav from "../../components/Menu";
import Slider from "../../UI_Elements/Slider";

//Header
import p1 from "../../assets/home/header/p1.png";
import p2 from "../../assets/home/header/p2.png";
import p3 from "../../assets/home/header/p3.png";
import p4 from "../../assets/home/header/p4.png";
import p5 from "../../assets/home/header/p5.png";
import p6 from "../../assets/home/header/p6.png";
import p7 from "../../assets/home/header/p7.png";
import p8 from "../../assets/home/header/p8.png";

import pt1 from "../../assets/home/testimonials/p1.jpg";
import pt2 from "../../assets/home/testimonials/p2.jpg";
import pt3 from "../../assets/home/testimonials/p3.jpg";
import pt4 from "../../assets/home/testimonials/p4.jpg";
import pt5 from "../../assets/home/testimonials/p5.jpg";

//Effects
import Effect1 from "../../assets/home/featured_mentors/effect_1.svg";
import Effect2 from "../../assets/home/featured_mentors/effect_2.svg";
import Effect3 from "../../assets/home/how_it_works/effect.svg";

//CTA
import cta from "../../assets/home/cta.png";

//Redux
import { connect } from "react-redux";

//Plugins
import Anime from "animejs";
import { MdSearch } from "react-icons/md";

import { Link } from "react-router-dom";

import axios from "axios";
import Preloader from "../../UI_Elements/Preloader";

import TestimonialsCard from "./TestimonialsCard";
import { FiClock, FiDollarSign } from "react-icons/fi";

import person from "../../assets/person.png";
import quotation from "../../assets/quotation.svg";

class Home extends Component {
  constructor() {
    super();

    this.searchMentors = React.createRef();

    this.findMentor = this.findMentor.bind(this);

    this.state = {
      featuredMentorLoad: true,
      featuredMentor: [],
    };
  }

  animeHeader() {
    Anime({
      targets: "div#Home header > figure > span",

      translateY: [
        {
          value: function (el, i) {
            return i * 2 + 30;
          },
          duration: 3500,
          delay: function (el, i) {
            return i * 100;
          },
        },
        {
          value: 0,
          duration: function (el, i) {
            return i * 500 + 2000;
          },
        },
      ],
      scale: [
        { value: 0.95, duration: 3000, delay: 1000 },
        { value: 1, duration: 2000, delay: 1000 },
      ],

      easing: "easeInOutQuad",
      direction: "alternate",
      loop: true,
    });
  }

  findMentor(e) {
    e.preventDefault();

    this.props.history.push({
      pathname: "/find",
      state: { keywords: this.searchMentors.current.value },
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.animeHeader();

    //Get featured mentors
    axios
      .post("/api/get-listings")
      .then((res) => {
        this.setState({ featuredMentor: res.data, featuredMentorLoad: false });
      })
      .catch((err) => {
        this.setState({ featuredMentorLoad: false });
      });
  }

  render() {
    const { featuredMentor, featuredMentorLoad } = this.state;
    const { user } = this.props;

    return (
      <div id="Home">
        <Nav />

        <header>
          <figure>
            <span
              className="p1"
              style={{ backgroundImage: `url(${p1})` }}
            ></span>
            <span
              className="p2"
              style={{ backgroundImage: `url(${p2})` }}
            ></span>
            <span
              className="p3"
              style={{ backgroundImage: `url(${p3})` }}
            ></span>
            <span
              className="p4"
              style={{ backgroundImage: `url(${p4})` }}
            ></span>
            <span
              className="p5"
              style={{ backgroundImage: `url(${p5})` }}
            ></span>
            <span
              className="p6"
              style={{ backgroundImage: `url(${p6})` }}
            ></span>
            <span
              className="p7"
              style={{ backgroundImage: `url(${p7})` }}
            ></span>
            <span
              className="p8"
              style={{ backgroundImage: `url(${p8})` }}
            ></span>
          </figure>

          <div className="content">
            <main>
              <h1 className="trl header title">
                Mentors to help you become your best self.
              </h1>
              <p className="trl header subtitle">
                Personal development for tomorrow’s leaders.
              </p>
              <form onSubmit={this.findMentor} className="stylish">
                <div>
                  <p>
                    <MdSearch size="25" />
                    <input
                      className="trl header searchMentors"
                      ref={this.searchMentors}
                      type="text"
                      placeholder="What type of advice do you need?"
                    />
                  </p>
                  <button
                    type="submit"
                    className="btn large trl header btnAction"
                  >
                    find a mentor
                  </button>
                </div>
              </form>

              <div className="flex popularCategories">
                <p className="trl header popular">Goals:</p>
                <div>
                  {this.props.settings.services.map((goal, i) => {
                    if (i > 1) return;
                    return (
                      <a
                        key={i}
                        className={`trl popular tag ${goal.id}`}
                        href="#"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/find",
                            state: { goal: goal.title },
                          });
                        }}
                      >
                        {goal.title}
                      </a>
                    );
                  })}
                </div>
              </div>
            </main>
          </div>
        </header>

        <section className="companies">
          <div className="content">
            <p className="trl titleLogos">
              Our mentors have experience in companies like
            </p>
            <div className="flex">
              <LazyLoad once height="200">
                <img src={svgApple} alt="Apple" />
                <img src={svgHubspot} alt="Hubspot" />
                <img src={svgAirbus} alt="Airbus" />
                <img src={svgDekra} alt="Dekra" />
              </LazyLoad>
            </div>
          </div>
        </section>

        <section className="featuredMentor">
          <LazyLoad once height="200">
            <img className="effect1" src={Effect1} alt="Effect" />
            <img className="effect2" src={Effect2} alt="Effect" />
          </LazyLoad>
          {featuredMentor.length > 1 ? (
            <div className="content">
              <h2 className="trl featuredMentors">Featured Mentors</h2>
              <p className="subtitle trl subtitleFeaturedMentors">
                These are some of our newest Mentors. Start here.
              </p>

              {featuredMentorLoad ? (
                <Preloader />
              ) : (
                <Slider className="noTranslate" dots>
                  {featuredMentor.map((rm, i) => {
                    return <CardMentor key={i} info={rm} />;
                  })}
                </Slider>
              )}
            </div>
          ) : null}
        </section>

        <section id="whatCanIarchieve">
          <div className="content">
            <h2 className="trl whatCanIarchieve title">What can I achieve?</h2>
            <p className="trl whatCanIarchieve subtitle">
              We all have things that we'd like to improve. Here are some to
              help you get started.
            </p>

            <article>
              <TestimonialsCard>
                <div className="testimonialsCard space">
                  <h3 className="trl whatCanIarchieve cvMakeover title">
                    CV makeover
                  </h3>
                  <p className="text trl whatCanIarchieve cvMakeover desc">
                    Your CV is the first impression that a recruiter will have
                    from you. Having a good CV is crucial, and will make your
                    job search a lot easier. Even if you're not looking for a
                    new position right now, having an up-to-date CV that sells
                    your experience to future potential employers is always a
                    good investment to make.
                  </p>
                  <div className="action">
                    <p className="desc">
                      <span>
                        <FiClock />{" "}
                        <span className="trl whatCanIarchieve aprox">
                          Aprox.
                        </span>
                        &nbsp;30 mins.
                      </span>
                      <span>
                        <FiDollarSign />{" "}
                        <span className="trl whatCanIarchieve from">From</span>
                        &nbsp;15€
                      </span>
                    </p>
                    <Link to="/find">
                      <button className="btn white trl btn whatCanIarchieve exploreMentors">
                        explore mentors
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="testimonialsCard">
                  <h3 className="trl whatCanIarchieve interviewPreparation title">
                    Interview preparation
                  </h3>
                  <p className="text trl whatCanIarchieve interviewPreparation desc">
                    Is there an important interview coming up? Book an 1h
                    session with a mentor with Human Resources experience that
                    will act as an interviewer and ask you some usual hiring
                    questions that you might hear your future interview. They
                    will also evaluate your answers and provide guidance on how
                    to answer them.
                  </p>
                  <div className="action">
                    <p className="desc">
                      <span>
                        <FiClock />{" "}
                        <span className="trl whatCanIarchieve aprox">
                          Aprox.
                        </span>
                        &nbsp;60 mins.
                      </span>
                      <span>
                        <FiDollarSign />{" "}
                        <span className="trl whatCanIarchieve from">From</span>
                        &nbsp;30€
                      </span>
                    </p>
                    <Link to="/find">
                      <button className="btn white trl btn whatCanIarchieve exploreMentors">
                        explore mentors
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="testimonialsCard space">
                  <h3 className="trl whatCanIarchieve linkedinOptimization title">
                    LinkedIn optimization
                  </h3>
                  <p className="text trl whatCanIarchieve linkedinOptimization desc">
                    It's hard to fit your entire career and make it apealling on
                    a single social network profile. Learn what are they
                    keywords that stand out in your field of expertise, create
                    perfect job description, headlines that get people to notice
                    you, inMail best practices. Our mentors know all these
                    profile tips to help you get hired faster.
                  </p>
                  <div className="action">
                    <p className="desc">
                      <span>
                        <FiClock />{" "}
                        <span className="trl whatCanIarchieve aprox">
                          Aprox.
                        </span>
                        &nbsp;60 mins.
                      </span>
                      <span>
                        <FiDollarSign />{" "}
                        <span className="trl whatCanIarchieve from">From</span>
                        &nbsp;30€
                      </span>
                    </p>
                    <Link to="/find">
                      <button className="btn white trl btn whatCanIarchieve exploreMentors">
                        explore mentors
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="testimonialsCard">
                  <h3 className="trl whatCanIarchieve jobSearchStrategy title">
                    Job search strategy
                  </h3>
                  <p className="text trl whatCanIarchieve jobSearchStrategy desc">
                    Job searching can be an overwhelming process. The right
                    strategy can make it easier and help you land a job much
                    faster. Learn to find jobs that aren't published, connect
                    with key stakeholders in your target company, plan your
                    messages for maximum impact and get interviews.
                  </p>
                  <div className="action">
                    <p className="desc">
                      <span>
                        <FiClock />{" "}
                        <span className="trl whatCanIarchieve aprox">
                          Aprox.
                        </span>
                        &nbsp;60 mins.
                      </span>
                      <span>
                        <FiDollarSign />{" "}
                        <span className="trl whatCanIarchieve from">From</span>
                        &nbsp;30€
                      </span>
                    </p>
                    <Link to="/find">
                      <button className="btn white trl btn whatCanIarchieve exploreMentors">
                        explore mentors
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="testimonialsCard space">
                  <h3 className="trl whatCanIarchieve negotiationCoaching title">
                    Negotiation coaching
                  </h3>
                  <p className="text trl whatCanIarchieve negotiationCoaching desc">
                    Negotiating is tough stuff. But it's a skill you seriously
                    need if you want to get ahead in your career. Let one of our
                    mentors help you navigate salary negotiations, earn a
                    promotion, or just get more comfortable fighting for what
                    you deserve. Get help to face difficult situations and
                    conversations at work with the guidance of an expert coach.
                  </p>
                  <div className="action">
                    <p className="desc">
                      <span>
                        <FiClock />{" "}
                        <span className="trl whatCanIarchieve aprox">
                          Aprox.
                        </span>
                        &nbsp;30 mins.
                      </span>
                      <span>
                        <FiDollarSign />{" "}
                        <span className="trl whatCanIarchieve from">From</span>
                        &nbsp;30€
                      </span>
                    </p>
                    <Link to="/find">
                      <button className="btn white trl btn whatCanIarchieve exploreMentors">
                        explore mentors
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="testimonialsCard">
                  <h3 className="trl whatCanIarchieve leadershipCoaching title">
                    Leadership coaching
                  </h3>
                  <p className="text trl whatCanIarchieve leadershiptCoaching desc">
                    All great leaders have gotten help along the way. Leadership
                    coaching can help you become a better manager, establish
                    yourself as a leader, and, in general, excel in any
                    organization. Builds a leader's capability to achieve short-
                    and long-term organizational goals. Get personalized,
                    customized, and one-on-one coaching sessions on the spot or
                    for a long term relationship.
                  </p>
                  <div className="action">
                    <p className="desc">
                      <span>
                        <FiClock />{" "}
                        <span className="trl whatCanIarchieve aprox">
                          Aprox.
                        </span>
                        &nbsp;30 mins.
                      </span>
                      <span>
                        <FiDollarSign />{" "}
                        <span className="trl whatCanIarchieve from">From</span>
                        &nbsp;30€
                      </span>
                    </p>
                    <Link to="/find">
                      <button className="btn white trl btn whatCanIarchieve exploreMentors">
                        explore mentors
                      </button>
                    </Link>
                  </div>
                </div>
              </TestimonialsCard>
            </article>
          </div>
        </section>

        <section className="howItWorks">
          <LazyLoad once height="200">
            <img className="effect1" src={Effect3} alt="Effect" />
          </LazyLoad>

          <div className="content">
            <h2 className="trl title howItWorks">How it works?</h2>
            <p className="trl subtitle howItWorks">
              Growth. At your pace. Anywhere.
            </p>

            <div className="flex">
              <div>
                <LazyLoad once height="150">
                  <figure>
                    <img src={HowItWorks_1} alt="Plan" />
                  </figure>
                </LazyLoad>
                <h3 className="trl plan title howItWorks">Plan</h3>
                <p className="trl plan text howItWorks">
                  Choose the skills that you’d like to get better at.
                </p>
              </div>

              <div>
                <LazyLoad once height="150">
                  <figure>
                    <img src={HowItWorks_2} alt="Find" />
                  </figure>
                </LazyLoad>
                <h3 className="trl find title howItWorks">Find</h3>
                <p className="trl find text howItWorks">
                  Find a mentor who can help you with your goal and connect to
                  book a session.
                </p>
              </div>

              <div>
                <LazyLoad once height="150">
                  <figure>
                    <img src={HowItWorks_3} alt="Learn" />
                  </figure>
                </LazyLoad>
                <h3 className="trl learn title howItWorks">Learn</h3>
                <p className="trl learn text howItWorks">
                  Take your session with your mentor directly in Mentory via
                  chat, audio or video.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="simpleToUseAndUnderstand">
          <LazyLoad once height="200">
            <img className="effect1" src={Effect1} alt="Effect" />
          </LazyLoad>

          <div className="content">
            <div className="flex">
              <div>
                <iframe
                  src="https://www.youtube.com/embed/lgiVLaIVfW4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div>
                <h2 className="trl presentation title">
                  New times require new ways of learning
                </h2>
                <p className="trl presentation subtitle">
                  Finding a mentor is rare, expensive and difficult. We’re here
                  to make learning opportunities available to our generation
                  through mentorship and conversations. Each one, helps one.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonialSection">
          <div className="content">
            <h2 className="trl testimonialSection title">
              Don’t take our word. Take theirs.
            </h2>
            <p className="trl testimonialSection subtitle">
              Unbiased comments from our community members.
            </p>

            <article>
              <Slider dots>
                <div className="card">
                  <div className="quotation">
                    <img src={quotation} alt="" />
                  </div>

                  <h4 className="trl testimonialSection card ip">
                    Interview preparation
                  </h4>
                  <p className="trl testimonialSection card ip desc">
                    After almost 9 months unemployed, I knew that I needed to
                    prepare well for my next interview. My mentor helped me to
                    rehearse so I had the confidence to face any difficult
                    question from the recruiter.{" "}
                  </p>
                  <div>
                    <span
                      style={{ backgroundImage: `url(${pt3})` }}
                      className="photo"
                    ></span>
                    <p>
                      <span>Davy H.</span>
                      <span>
                        Marketing Specialist <br /> Teleperformance
                      </span>
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="quotation">
                    <img src={quotation} alt="" />
                  </div>

                  <h4 className="trl testimonialSection card lo">
                    LinkedIn optimization
                  </h4>
                  <p className="trl testimonialSection card lo desc">
                    It's hard to fit your entire career on a single social
                    network profile and make it apealling . Learn what are they
                    keywords that stand out in your field of expertise,
                    headlines and descriptions that get people to notice you or
                    inMail best practices. Our mentors know all these tips to
                    help you get hired faster.
                  </p>
                  <div>
                    <span
                      style={{ backgroundImage: `url(${pt2})` }}
                      className="photo"
                    ></span>
                    <p>
                      <span>Maeva J.</span>
                      <span>
                        HR Advisor <br /> Nike
                      </span>
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="quotation">
                    <img src={quotation} alt="" />
                  </div>

                  <h4 className="trl testimonialSection card ca">
                    Career advice
                  </h4>
                  <p className="trl testimonialSection card ca desc">
                    I was looking to change the focus of my career from
                    traditional Project Management to Agile. My mentor pointed
                    me to valuable resources and tips that saved me a lot of
                    time.
                  </p>
                  <div>
                    <span
                      style={{ backgroundImage: `url(${pt4})` }}
                      className="photo"
                    ></span>
                    <p>
                      <span>Daniel J.</span>
                      <span>
                        Project Manager <br /> Sopra Steria
                      </span>
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="quotation">
                    <img src={quotation} alt="" />
                  </div>

                  <h4 className="trl testimonialSection card lc">
                    Leadership coaching
                  </h4>
                  <p className="trl testimonialSection card lc desc">
                    After being promoted from individual contributor to manager,
                    I felt that there were many things that I needed to learn
                    quick to lead my team as they deserve. Following my
                    manager’s advice I connected with a leadership coach whose
                    advice is being invaluable and is helping me to become a
                    better leader.
                  </p>
                  <div>
                    <span
                      style={{ backgroundImage: `url(${pt1})` }}
                      className="photo"
                    ></span>
                    <p>
                      <span>Danny H.</span>
                      <span>
                        Sales Director <br /> Rentals United
                      </span>
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="quotation">
                    <img src={quotation} alt="" />
                  </div>

                  <h4 className="trl testimonialSection card nc">
                    Negotiation coaching
                  </h4>
                  <p className="trl testimonialSection card nc desc">
                    I always thought that if you work hard wait long enough,
                    promotions would come naturally. I was wrong. Sometimes you
                    just need somebody to push you and show you that you need to
                    make your voice heard and don’t be afraid of negotiating
                    what you deserve.
                  </p>
                  <div>
                    <span
                      style={{ backgroundImage: `url(${pt5})` }}
                      className="photo"
                    ></span>
                    <p>
                      <span>Andrea B.</span>
                      <span>
                        Project Manager <br /> Ferrovial
                      </span>
                    </p>
                  </div>
                </div>
              </Slider>
            </article>
          </div>
        </section>

        <section className="ctaMakeMoney">
          <div className="content">
            <div className="flex">
              <div>
                <h2 className="trl title cta">
                  Make money sharing what you know.
                </h2>
                <p className="trl subtitle cta">
                  Add an extra source of income by helping others to get better
                  at what you already know. From where you are. At your pace.
                </p>
                <Link to="/become-a-mentor">
                  <button className="btn large trl button cta">
                    Become a mentor
                  </button>
                </Link>
              </div>
              <LazyLoad once height="500">
                <img src={cta} alt="Make money sharing what you know." />
              </LazyLoad>
            </div>
          </div>
        </section>

        <SignUp />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Home);
