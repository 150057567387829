import axios from "axios";

export default (mentorUsername, menteeUsername) => {
  if (
    window.confirm(
      "Are you sure that you would like to cancel this request? If you continue, we will refund you your money in around 10 working days.",
    )
  ) {
    axios
      .post("/api/manage-request/delete-request-session", {
        mentorUsername,
        menteeUsername,
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
