import React, { Component } from "react";

import "./sass/SelectCurrencyModal.scss";

//Form
import Dropdown from "../../UI_Elements/Form/Dropdown";

import Modal from "../../UI_Elements/Modal";

import { getCodes, checkCode } from "../../inc/data/Currencies";

import axios from "axios";

import Preloader from "../../UI_Elements/Preloader";

import { FiAlertCircle } from "react-icons/fi";

import Response from "../../UI_Elements/Response";
import showResponse from "../../UI_Elements/Response/showResponse";

//Redux
import { connect } from "react-redux";

class SelectCurrencyModal extends Component {
  constructor() {
    super();

    this.state = {
      currency: null,
      sent: false,
    };

    this.setCurrency = this.setCurrency.bind(this);
    this.ModalSetCurrency = React.createRef();
  }

  setCurrency(e) {
    e.preventDefault();

    this.setState({ sent: true });

    axios
      .post(`/api/set-currency/${this.state.currency}`)
      .then((res) => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        this.setState({ sent: false });
        console.log(err);
        showResponse("SetCurrencyError");
      });
  }

  componentDidMount() {
    //Get conversion currencies
    let myCurrency = this.props.user.currency;

    if (myCurrency.trim() == "" || !checkCode(myCurrency)) {
      this.ModalSetCurrency.current._openCloseModal(true);
    }
  }

  render() {
    const { sent } = this.state;

    return (
      <React.Fragment>
        <Response
          id="SetCurrencyError"
          type="error"
          title="Invalid currency"
          description="Please, select a valid currency"
          btnText="Close"
          btnAction="close"
        />

        <Modal
          lock
          ref={this.ModalSetCurrency}
          className="ModalSetCurrency"
          title="Select a currency"
        >
          <FiAlertCircle size="60" />
          <h2>Select a currency first</h2>
          <p>
            This will be your default currency booking and publishing listings
            in Mentory
          </p>
          <form className="simple" onSubmit={this.setCurrency}>
            <Dropdown
              className="dropdownCurrency"
              onChange={(currency) => this.setState({ currency })}
              values={getCodes()}
              placeholder="Select a currency"
            />

            {sent ? (
              <Preloader />
            ) : (
              <button type="submit" className="btn large">
                save
              </button>
            )}
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(SelectCurrencyModal);
