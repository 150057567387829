import React, { Component } from "react";

// import Currency from 'react-currency-formatter';

class Money extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { value, currency } = this.props;

    return "Currency component";
  }
}

/* 
<Currency
  quantity={typeof value == 'function' ? value() : value}
  currency={currency ? currency : 'USD'}
/>
*/

export default Money;
