import React, { Component } from "react";

import classnames from "classnames";

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxState: props.checked ? "checked" : "",
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { checkboxState } = this.state;
    const { id } = this.props;

    if (this.props.onChange) {
      var arrayValues = [];
      document.querySelectorAll(`#${id} input`).forEach((input) => {
        if (input.checked) arrayValues.push(input.value);
      });

      this.props.onChange(arrayValues);
    }

    this.setState({
      checkboxState: checkboxState == "checked" ? "" : "checked",
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      this.setState({ checkboxState: nextProps.checked });
    }
  }

  render() {
    const { checkboxState } = this.state;
    const { label, className, id, value } = this.props;

    let labelVariable;
    if (typeof label == "string") {
      labelVariable = label
        .replace(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/gm, "_")
        .toLowerCase();
    }

    return (
      <p
        id={id}
        className={classnames({
          Checkbox: true,
          [className]: className,
        })}
      >
        <label>
          <input
            type="checkbox"
            className="filled-in"
            checked={checkboxState}
            onChange={(e) => this.onChange(e)}
            value={value ? value : label}
          />

          <span className={`trl findMentor checkboxLabel ${labelVariable}`}>
            {label}
          </span>
        </label>
      </p>
    );
  }
}

export default Checkbox;
