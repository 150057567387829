import React, { Component } from "react";

import "./sass/Footer.scss";

import Logo from "../../assets/logo.png";

import { FaInstagram, FaLinkedinIn } from "react-icons/fa";

import { Link } from "react-router-dom";

class Footer extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <footer className="footer trlGlobal">
        <div className="content">
          <div className="flex">
            <div>
              <img width="150" src={Logo} alt="Mentory.me" />
              <p className="trl footer headline">Welcome to your best self.</p>
              <br />
              <p className="trl footer about">
                Mentory is the first person to person personal development
                platform focused on millennial talent. We're in a journey to
                help people from our generation to become tomorrow's leaders one
                conversation at a time.
              </p>
              <br />
              <p className="trl footer copyRight">
                &copy; 2019, Mentory. All Rights Reserved.
              </p>
            </div>

            <div className="flex">
              <ul className="mentory">
                <li className="trl footer mentoryListTitle">Mentory</li>

                <li>
                  <a
                    className="trl footer blogLink"
                    target="_blank"
                    href="http://blog.mentory.me/"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <Link className="trl footer theIdeaLink" to="/the-idea">
                    The Idea
                  </Link>
                </li>
                <li>
                  <Link className="trl footer pricing" to="/pricing">
                    Pricing
                  </Link>
                </li>
                <li>
                  <a
                    className="trl footer helpLink"
                    target="_blank"
                    href="mailto:hello@mentory.me"
                  >
                    Help
                  </a>
                </li>
                <li>
                  <a
                    className="trl footer privacyPolicyLink"
                    target="_blank"
                    href="https://leads.mentory.me/politica-privacidad/"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <div className="translateApp setLang"></div>
                </li>
              </ul>

              <div className="mentors_mentees">
                <ul>
                  <li className="trl footer mentorsListTitle">Mentors</li>

                  <li>
                    <Link
                      className="trl footer becomeMentorLink"
                      to="/become-a-mentor"
                    >
                      Become a mentor
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className="trl footer menteesListTitle">Mentees</li>

                  <li>
                    <Link className="trl footer findMentorLink" to="/find">
                      Find a mentor
                    </Link>
                  </li>
                  <li>
                    <Link className="trl footer theUpgradeLink" to="/upgrade">
                      Upgrade
                    </Link>
                  </li>
                </ul>
              </div>

              <ul className="getInTouch">
                <li className="trl footer getIntouchListTitle">Get in Touch</li>

                <li>
                  <a href="mailto:hello@mentory.me">hello@mentory.me</a>
                </li>
                <li className="social">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/mentoryme/about/"
                  >
                    <FaLinkedinIn size="15" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/mentory.me/"
                  >
                    <FaInstagram size="15" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
