//Redux
import Store from "../redux/store";
import { UPDATE_USER, SETTINGS } from "../redux/actions";

import { Socket, getCurrentStatusFromAllUsers } from "./SockeIO.cliente";

export default (user) => {
  //Set info to user
  Store.dispatch(UPDATE_USER(user));

  //Set SETTINGS userLogged to TRUE
  Store.dispatch(SETTINGS("userLogged", true));

  //Set user as ONLINE
  Socket.emit("logged_users", user);

  //Get status
  getCurrentStatusFromAllUsers();
};
