import React, { Component } from "react";

import "./sass/ResponsePendingMentee.scss";

import { MdMoreVert } from "react-icons/md";
import defaultPhoto from "../../../assets/default.png";

import classnames from "classnames";

import moment from "moment-timezone";

import Money from "../../../inc/Money";

import { Link } from "react-router-dom";

import { connect } from "react-redux";

import CANCEL_REQUEST from "../../../inc/CANCEL_REQUEST";

class ResponsePendingMentee extends Component {
  constructor() {
    super();

    this.state = {
      openedSettingsMenu: false,
    };

    this.handleClick = this.handleClick.bind(this);

    this.containerSettings = React.createRef();
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick(e) {
    if (this.containerSettings.contains(e.target)) return;

    this.setState({ openedSettingsMenu: false });
  }

  render() {
    const { openedSettingsMenu } = this.state;
    const { session, settings } = this.props;

    return (
      <div className="ResponsePendingMentee trlGlobal">
        <div
          ref={(node) => (this.containerSettings = node)}
          onClick={() => this.setState({ openedSettingsMenu: true })}
          className="settings"
        >
          <MdMoreVert size="22" />
          <ul
            className={classnames({
              dropdownOpt: true,
              active: openedSettingsMenu ? true : false,
            })}
          >
            <li
              className="trl ResponsePendingMentee editRequest"
              onClick={this.props.onClickEditRequest}
            >
              Edit request
            </li>
            <li
              className="trl ResponsePendingMentee cancelRequest"
              onClick={() =>
                CANCEL_REQUEST(
                  this.props.session.mentorUsername,
                  this.props.session.menteeUsername,
                )
              }
            >
              Cancel request
            </li>
            <li className="link">
              <Link
                className="trl ResponsePendingMentee viewProfile"
                to={`/${settings.publicProfile}/${session.mentorUsername}`}
              >
                View profile
              </Link>
            </li>
            <li className="link">
              <Link
                className="trl ResponsePendingMentee contactMentor"
                to="/connect"
              >
                Contact mentor
              </Link>
            </li>
            <li
              className="trl ResponsePendingMentee reportUser"
              onClick={this.props.onClickReportUser}
            >
              Report user
            </li>
          </ul>
        </div>
        <span
          className="photo"
          style={{
            backgroundImage: `url("${session.mentorObject.userPhoto.trim() != "" ? session.mentorObject.userPhoto : defaultPhoto}")`,
          }}
        ></span>
        <div>
          <h4>
            {session.mentorObject.name} {session.mentorObject.surname}
          </h4>
          <p className="description">{session.titleListing}</p>
          <p className="received">
            <span className="trl ResponsePendingMentee sentSpan">Sent</span>{" "}
            {`${moment(session.whenRequestedSession).format("DD MMMM YYYY")}`}
          </p>
          <p className="youWillMake">
            {parseInt(session.price.mentee) > 0 ? (
              <React.Fragment>
                <span className="trl ResponsePendingMentee youWillPaySpan">
                  You will pay
                </span>{" "}
                <Money
                  value={session.price.mentee / 100}
                  currency={this.props.user.currency}
                />
              </React.Fragment>
            ) : (
              <span className="trl ResponsePendingMentee free">FREE</span>
            )}
          </p>
          <span className="trl ResponsePendingMentee status">
            Response pending
          </span>
        </div>
      </div>
    );
  }
}

const mapStateTopPros = (state) => {
  return state;
};
export default connect(mapStateTopPros)(ResponsePendingMentee);
