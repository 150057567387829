import React, { Component } from "react";

//Components
import classnames from "classnames";

//SASS
import "./sass/Timeline.scss";

import { FiX } from "react-icons/fi";

class Timeline extends Component {
  constructor(props) {
    super(props);

    this.removeValue = this.removeValue.bind(this);
  }

  removeValue(item) {
    const { items } = this.props;

    const newItems = items.filter((_i_) => {
      return (
        _i_.title != item.title &&
        _i_.description != item.description &&
        _i_.date != item.date
      );
    });

    if (this.props.removed) this.props.removed(newItems);
  }

  render() {
    const { items, title, remove } = this.props;

    return (
      <div className="Timeline">
        <p
          className={`trl title timeline ${window.TranslateApp.clearString(title)}`}
        >
          {title}
        </p>

        <div className="container">
          {items.map((item, i) => {
            return (
              <div
                onClick={() => (remove ? this.removeValue(item) : null)}
                className={remove ? "admin" : ""}
                key={i}
              >
                {remove ? (
                  <span className="delete">
                    <FiX />
                  </span>
                ) : null}

                <p>
                  {item.title}
                  <br />
                  <span>{item.description}</span>
                </p>
                <span>{item.date}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Timeline;
