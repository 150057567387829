import React, { Component } from "react";

import classnames from "classnames";

//Assets
import defaultPhoto from "../../assets/default.png";

//Redux
import { connect } from "react-redux";

class Contact extends Component {
  render() {
    const { contact, contactSelected, allMessages, i } = this.props;
    const { settings } = this.props;

    var x = 0;
    settings.notifications.forEach((n) => (n == contact.chatID ? ++x : null));

    return (
      <div
        key={i}
        onClick={() => this.props.openChat(contact)}
        className={classnames({
          contact: true,
          selected:
            contactSelected &&
            contactSelected.usr.username == contact.usr.username,
        })}
      >
        <div>
          <span
            className={classnames({
              photo: true,
              on: settings.allUsersStatus[contact.usr.username]
                ? settings.allUsersStatus[contact.usr.username].online
                : false,
            })}
            style={{
              backgroundImage: `url(${contact.usr.userPhoto.trim() != "" ? contact.usr.userPhoto : defaultPhoto})`,
            }}
          ></span>
          <div>
            <p>
              {contact.usr.name} {contact.usr.surname}
            </p>
            {allMessages[contact.chatID] ? (
              <p>
                {allMessages[contact.chatID][
                  allMessages[contact.chatID].length - 1
                ].who == this.props.user.username
                  ? "You: "
                  : `${contact.usr.name}: `}
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      allMessages[contact.chatID][
                        allMessages[contact.chatID].length - 1
                      ].text.length > 20
                        ? allMessages[contact.chatID][
                            allMessages[contact.chatID].length - 1
                          ].text.substring(0, 20) + "..."
                        : allMessages[contact.chatID][
                            allMessages[contact.chatID].length - 1
                          ].text,
                  }}
                ></span>
              </p>
            ) : null}
          </div>
        </div>
        {x != 0 ? (
          <span className="notification">
            <i>{x}</i>
          </span>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(Contact);
