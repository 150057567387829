import React, { Component } from "react";

import "./sass/ReportUser.scss";

import defaultPhoto from "../../assets/default.png";

import Modal from "../../UI_Elements/Modal";
import RadioButtom from "../../UI_Elements/Form/RadioButton";
import Input from "../../UI_Elements/Form/Input";

import Preloader from "../../UI_Elements/Preloader";

import axios from "axios";

//Redux
import { connect } from "react-redux";

//Response
import Response from "../../UI_Elements/Response";
import showResponse from "../../UI_Elements/Response/showResponse";

class ReportUser extends Component {
  constructor() {
    super();

    this.state = {
      sentForm: false,
    };

    this.why = React.createRef();
    this.details = React.createRef();
  }

  async REPORT_USER(e, username) {
    e.preventDefault();

    //Sent form
    this.setState({ sentForm: true });

    const { refModal } = this.props;

    const why = this.why.current.state.valueSelected;
    const details = this.details.current.state.inputValue;

    await axios
      .post(`/api/report-user/${username}`, { why, details })
      .then((res) => {
        showResponse("reportUserSuccess", res.data);
        refModal.current._openCloseModal(false);

        //Remove selected
        document
          .querySelectorAll(".ReportUser input")
          .forEach((input) => (input.checked = false));
        //Clear textarea
        document.querySelector(".ReportUser textarea").value = "";

        this.setState({ sentForm: false });
      })
      .catch((err) => {
        showResponse("reportUserError", err.response.data);

        this.setState({ sentForm: false });
      });
  }

  render() {
    const { refModal } = this.props;
    const { sentForm } = this.state;

    const { userPhoto, name, surname, username, titleListing } = this.props;

    return (
      <React.Fragment>
        <Response
          id="reportUserSuccess"
          type="success"
          title="User reported"
          description=""
          btnText="Close"
          btnAction="close"
        />
        <Response
          id="reportUserError"
          type="error"
          title="Something went wrong"
          description=""
          btnText="Close"
          btnAction="close"
        />

        <Modal
          ref={refModal}
          title="Report user"
          className="trlGlobal ReportUser"
          iconToClose
        >
          {name && surname && username ? (
            <React.Fragment>
              <span
                style={{
                  backgroundImage: `url(${userPhoto.trim() != "" ? userPhoto : defaultPhoto})`,
                }}
                className="photo"
              ></span>
              <h4>{titleListing}</h4>
              <p className="menteeName">
                {name} {surname}
              </p>

              <form
                onSubmit={(e) => this.REPORT_USER(e, username)}
                className="simple"
              >
                <RadioButtom
                  trl
                  ref={this.why}
                  type="v"
                  title="Why are you reporting this user?"
                  labels={[
                    "Contains a suspicious link.",
                    "Looks like spam.",
                    "Messages are threatening or abusive.",
                    "Requesting to pay or connect outside of Mentory.",
                    "Something else.",
                  ]}
                  values={[
                    "Contains a suspicious link.",
                    "Looks like spam.",
                    "Messages are threatening or abusive.",
                    "Requesting to pay or connect outside of Mentory.",
                    "Something else.",
                  ]}
                />
                <Input
                  id="additionalDetailsReportUser"
                  ref={this.details}
                  textarea
                  label="Additional details"
                  placeholder="Message"
                />

                <div className="actions flex">
                  {sentForm ? (
                    <Preloader />
                  ) : (
                    <button className="trl modalReportUser submit btn">
                      Submit
                    </button>
                  )}
                  <p
                    className="trl modalReportUser cancel"
                    onClick={() => refModal.current._openCloseModal(false)}
                  >
                    cancel
                  </p>
                </div>
              </form>
            </React.Fragment>
          ) : null}
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(ReportUser);
