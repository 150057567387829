import React, { Component } from "react";

//Components
import BookSessionInputTime from "../../UI_Elements/Form/BookSessionInputTime";
import Input from "../../UI_Elements/Form/Input";
import InputDate from "../../UI_Elements/Form/InputDate";
import RadioButton from "../../UI_Elements/Form/RadioButton";
import Modal from "../../UI_Elements/Modal";
import Card from "../../components/Card";

import Preloader from "../../UI_Elements/Preloader";
import showResponse from "../../UI_Elements/Response/showResponse";

//Assets
import { IoIosMoon, IoIosSunny, IoIosArrowDown } from "react-icons/io";
import defaultPhoto from "../../assets/default.png";

import { Link } from "react-router-dom";
import Money from "../../inc/Money";
import conversion from "./actions/conversion";
import timezoneConverter from "../../inc/timezoneConverter";
import moment from "moment-timezone";
import axios from "axios";

//Redux
import Store from "../../redux/store";
import { UPDATE_USER } from "../../redux/actions";
import { connect } from "react-redux";

const today = new Date();

class BookingModal extends Component {
  constructor() {
    super();

    this.state = {
      sessionDateMentee: new Date(),
      sessionDateMentor: moment(),

      valueSessionDateMentee: 0,
      valueSessionDateMentor: 0,

      timeMentor: "0",
      timeMentee: "12:00 PM",

      //Sent
      payAndRequestSessionSent: false,

      CARDS: null,
      lengthSessionArray: null,
      rate: 1,
    };

    this.CREATE_SESSION = this.CREATE_SESSION.bind(this);
    this.updateSessionTimeDate = this.updateSessionTimeDate.bind(this);
    this.changeTimeMentee = this.changeTimeMentee.bind(this);
    this.handleChangeSessionDate = this.handleChangeSessionDate.bind(this);
    this.selectLength = this.selectLength.bind(this);

    this.helpDescription = React.createRef();
  }

  selectLength(sessionLength) {
    this.setState({ lengthSessionArray: sessionLength });
  }
  changeTimeMentee(date) {
    this.updateSessionTimeDate(date, this.state.sessionDateMentee);
  }
  handleChangeSessionDate(newDate) {
    this.updateSessionTimeDate(this.state.valueSessionDateMentee, newDate);
  }
  updateSessionTimeDate(valueDate, sessionDateMentee) {
    const { timeMentee } = this.state;
    const { user, mentor } = this.props;

    const TIME_ZONE = timezoneConverter(
      mentor.timezone,
      user.timezone,
      sessionDateMentee,
      timeMentee,
    );
    let hours = valueDate / 60;
    let WithTimeZone = hours + TIME_ZONE.offset;

    if (WithTimeZone > 23.5) {
      this.setState({
        valueSessionDateMentor: (WithTimeZone - 24) * 60,
        valueSessionDateMentee: valueDate,
        timeMentor: TIME_ZONE.timeMentor,

        sessionDateMentor: moment(sessionDateMentee).add(1, "days"),
        sessionDateMentee: sessionDateMentee,
      });
    } else if (WithTimeZone < 0) {
      this.setState({
        valueSessionDateMentor: (WithTimeZone + 24) * 60,
        valueSessionDateMentee: valueDate,
        timeMentor: TIME_ZONE.timeMentor,

        sessionDateMentor: moment(sessionDateMentee).subtract(1, "days"),
        sessionDateMentee: sessionDateMentee,
      });
    } else {
      this.setState({
        valueSessionDateMentor: WithTimeZone * 60,
        valueSessionDateMentee: valueDate,
        timeMentor: TIME_ZONE.timeMentor,

        sessionDateMentor: moment(sessionDateMentee),
        sessionDateMentee: sessionDateMentee,
      });
    }
  }

  CREATE_SESSION(e) {
    e.preventDefault();

    this.setState({ payAndRequestSessionSent: true });

    const mentor = this.props.mentor;
    const mentee = this.props.user;

    let sessionLength = this.state.lengthSessionArray,
      menteeDate = moment(this.state.sessionDateMentee).format("MM/DD/YYYY"),
      menteeTime = this.state.timeMentee,
      mentorDate = this.state.sessionDateMentor.format("MM/DD/YYYY"),
      mentorTime = this.state.timeMentor,
      helpDescription = this.helpDescription.current.state.inputValue,
      cardID = this.state.CARDS.values[this.state.CARDS.selected],
      titleListing = this.props.listing.title,
      slug = this.props.listing.slug,
      serviceSelected = this.props.serviceSelected;

    axios
      .post("/api/create-session", {
        sessionLength,
        menteeDate,
        menteeTime,
        mentorDate,
        mentorTime,
        helpDescription,
        cardID,
        mentorUsername: mentor.username,
        menteeUsername: mentee.username,
        titleListing,
        slug,
        serviceSelected,
      })
      .then((res) => {
        console.log("res: ", res);

        this.setState({ payAndRequestSessionSent: false });

        showResponse("PaymentSuccess", res.data.response);
        Store.dispatch(UPDATE_USER(res.data.user));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);

        this.setState({ payAndRequestSessionSent: false });

        showResponse("PaymentError", err.response.data);
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.serviceSelected)
      return this.setState({ serviceSelected: nextProps.serviceSelected });
    if (nextProps.serviceSelected.id !== this.props.serviceSelected.id) {
      return this.setState({ serviceSelected: nextProps.serviceSelected });
    }
  }
  async componentDidMount() {
    const { user, mentor, listing } = this.props;

    //Get conversion currencies
    const rate = await conversion(user.currency, mentor.currency, listing.paid);

    //To init = 12 * 60 = 12:00
    this.updateSessionTimeDate(12 * 60, new Date());

    //Check cards
    var CARDS = {
      html: [],
      values: [],
      selected: 0,
    };

    if (user.cards) {
      user.cards.forEach((card, i) => {
        if (card.defaultCard) CARDS.selected = i.toString();

        CARDS.values.push(card.id);
        CARDS.html.push(<Card cardNumber={card.last4} brand={card.brand} />);
      });
    }

    this.setState({ CARDS, rate: rate ? rate : 1 });
  }

  render() {
    const {
      valueSessionDateMentee,
      valueSessionDateMentor,
      timeMentor,
      timeMentee,
      sessionDateMentee,
      sessionDateMentor,
      lengthSessionArray,
      CARDS,
      rate,
    } = this.state;

    const { user, listing, mentor, serviceSelected } = this.props;

    return (
      <Modal
        ref={this.props.refModal}
        iconToClose
        className="ModalBookinDetails"
        title="Booking details"
      >
        {!CARDS || !serviceSelected ? null : (
          <form onSubmit={this.CREATE_SESSION} className="simple">
            <div className="serviceRequested">
              <p className="trl eachListing serviceRequested">
                Service requested
              </p>
              <p>{serviceSelected.title}</p>
            </div>
            <div className="selectBox">
              <p className="trl eachListing sessionLength">Session length</p>
              <div>
                {serviceSelected.selected.map((length, i) => {
                  return (
                    <div
                      key={i}
                      className={lengthSessionArray === length ? "active" : ""}
                      onClick={() => this.selectLength(length)}
                    >
                      <span>
                        {length} min
                        <br />
                        {listing.paid ? (
                          <Money
                            value={listing.pricePerHour / (60 / length)}
                            currency={mentor.currency}
                          />
                        ) : (
                          <span className="trl eachListing free">FREE</span>
                        )}
                      </span>
                      {mentor.currency != user.currency ? (
                        <span className="openexchangerates">
                          <small>
                            <Money
                              value={
                                (listing.pricePerHour / (60 / length)) * rate
                              }
                              currency={user.currency}
                            />
                          </small>
                        </span>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>

            <InputDate
              label="Pick a date and time"
              icon={<IoIosArrowDown />}
              selected={sessionDateMentee}
              onSelect={this.handleChangeSessionDate} //when day is clicked
              maxDate={today.setDate(today.getDate() + 20)}
            />

            <div className="SelectTime">
              <p className="trl eachListing yourTime">Your time</p>
              <div className="flex menteeTime">
                <div className="flex">
                  <div className="flex">
                    <span
                      className="photo"
                      style={{
                        backgroundImage: `url(${user.userPhoto.trim() ? user.userPhoto : defaultPhoto})`,
                      }}
                    ></span>
                    <p>
                      <span className="time">{timeMentee}</span>
                      <span className="date">
                        {moment(sessionDateMentee).format("dddd, MMMM D")}
                      </span>
                      <span className="location">{user.timezone}</span>
                    </p>
                  </div>
                  {valueSessionDateMentee / 60 < 7 ||
                  valueSessionDateMentee / 60 > 20 ? (
                    <IoIosMoon size="25" />
                  ) : (
                    <IoIosSunny size="25" />
                  )}
                </div>
                <BookSessionInputTime
                  value={valueSessionDateMentee}
                  formated={(dateString) =>
                    this.setState({ timeMentee: dateString })
                  }
                  onChange={this.changeTimeMentee}
                />
              </div>

              <br />
              <br />
              <br />

              <p>
                {mentor.name}
                <span className="trl eachListing mentorTime">'s time</span>
              </p>
              <div className="flex mentorTime">
                <div className="flex">
                  <div className="flex">
                    <span
                      className="photo"
                      style={{
                        backgroundImage: `url(${mentor.userPhoto.trim() != "" ? mentor.userPhoto : defaultPhoto})`,
                      }}
                    ></span>
                    <p>
                      <span className="time">{timeMentor}</span>
                      <span className="date">
                        {sessionDateMentor.format("dddd, MMMM D")}
                      </span>
                      <span className="location">{mentor.timezone}</span>
                    </p>
                  </div>
                  {valueSessionDateMentor / 60 < 7 ||
                  valueSessionDateMentor / 60 > 20 ? (
                    <IoIosMoon size="25" />
                  ) : (
                    <IoIosSunny size="25" />
                  )}
                </div>
                <BookSessionInputTime
                  tooltip={false}
                  value={valueSessionDateMentor}
                />
              </div>
            </div>

            <Input
              id="helpDescription"
              ref={this.helpDescription}
              textarea
              label={
                <React.Fragment>
                  <span className="trl listing helpDescription1">Let</span>{" "}
                  {mentor.name}{" "}
                  <span className="trl listing helpDescription2">
                    know what you need help with
                  </span>
                </React.Fragment>
              }
              placeholder="The more context you give, the more prepared the mentor can be for the call."
            />

            {listing.paid ? (
              <React.Fragment>
                <div className="paymentSelection">
                  <RadioButton
                    type="v"
                    title="Payment method"
                    checked={CARDS.selected}
                    values={CARDS.values}
                    labels={CARDS.html}
                    onChange={(value, position) => {
                      var update = CARDS;
                      update.selected = position;
                      this.setState({ CARDS: update });
                    }}
                  />

                  <Link
                    to="/settings#billing"
                    className="trl eachListing addNewPaymentMethod"
                  >
                    + Add new payment method
                  </Link>
                </div>
                {CARDS && CARDS.values.length > 0 ? (
                  <div className="actions flex">
                    {this.state.payAndRequestSessionSent ? (
                      <Preloader />
                    ) : (
                      <button className="btn trl eachListing payAndRequestSession">
                        Pay and request session
                      </button>
                    )}

                    <p
                      className="trl eachListing cancel"
                      onClick={() =>
                        this.ModalBookSessionRef.current._closeModal()
                      }
                    >
                      cancel
                    </p>
                  </div>
                ) : (
                  <React.Fragment>
                    <br />
                    <p className="trl eachListing addPaymentBeforeRequesting">
                      Add a payment method before requesting a session.
                    </p>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : this.state.payAndRequestSessionSent ? (
              <Preloader />
            ) : (
              <button className="btn trl eachListing requestSession">
                Request session
              </button>
            )}
          </form>
        )}
      </Modal>
    );
  }
}

const mapStateToProp = (state) => {
  return state;
};
export default connect(mapStateToProp)(BookingModal);
