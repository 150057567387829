import { createStore, combineReducers, applyMiddleware } from "redux";

//Reducers
import user from "./reducers/User";
import settings from "./reducers/Settings";

const reducers = combineReducers({
  user,
  settings,
});

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
