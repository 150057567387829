import React, { Component } from "react";

import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import "react-datepicker/dist/react-datepicker.css";
import "./sass/InputDate.scss";

import classnames from "classnames";

class InputDate extends Component {
  constructor() {
    super();

    this._calendar = React.createRef();
  }

  render() {
    const {
      label,
      icon,
      value,
      selected,
      onSelect,
      description,
      time,
      minDate,
      maxDate,
      dateFormat,
      placeholder,
      showMonthYearPicker,
      className,
    } = this.props;

    return (
      <div
        className={classnames({
          Input: true,
          InputDate: true,
          [className]: className,
        })}
        onClick={() => this._calendar.setOpen(true)}
      >
        {label ? (
          <label
            onClick={(e) => e.preventDefault()}
            className={`${label ? "trl label " + window.TranslateApp.clearString(label) : ""}`}
          >
            {label}
          </label>
        ) : null}

        <p className="flex">
          <DatePicker
            className={`${label ? "trl datePicker " + window.TranslateApp.clearString(label) : ""}`}
            ref={(c) => (this._calendar = c)}
            selected={selected}
            onChange={onSelect}
            dateFormat={dateFormat ? dateFormat : "MMMM d, yyyy"}
            showMonthYearPicker={showMonthYearPicker ? true : false}
            yearDropdownItemNumber={40}
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            scrollableYearDropdown
            minDate={minDate ? minDate : new Date("1920")}
            maxDate={maxDate ? maxDate : new Date()}
            showTimeSelect={time ? true : false}
            timeFormat="HH:mm"
            placeholderText={placeholder ? placeholder : null}
            popperPlacement="bottom-start"
            popperModifiers={{
              flip: {
                enabled: false,
              },
            }}
          />
          {icon ? icon : null}
        </p>
        {description ? (
          <small
            className={`trl description ${window.TranslateApp.clearString(description)}`}
          >
            {description}
          </small>
        ) : null}
      </div>
    );
  }
}

export default InputDate;
