import React, { Component } from "react";

import "./sass/MyListings.scss";

import StarRatings from "../../../UI_Elements/StarRatings";
import classnames from "classnames";

//Assets
import { FiGlobe, FiMapPin, FiSmartphone } from "react-icons/fi";

import { MdSettings } from "react-icons/md";

import axios from "axios";

//Redux
import Store from "../../../redux/store";
import { DELETE_LISTING } from "../../../redux/actions";

import { Link } from "react-router-dom";

import ShowPrice from "../../../inc/ShowPrice";

class MyListing extends Component {
  constructor() {
    super();

    this.state = {
      openedSettingsMenu: false,
    };

    this.containerSettings = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.openMenuSettings = this.openMenuSettings.bind(this);
    this.DELETE_LISTING = this.DELETE_LISTING.bind(this);
    this.START_PAUSE_LISTING = this.START_PAUSE_LISTING.bind(this);
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick(e) {
    if (this.containerSettings.contains(e.target)) return;

    this.setState({ openedSettingsMenu: false });
  }

  openMenuSettings(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ openedSettingsMenu: true });
  }

  DELETE_LISTING() {
    const { listing } = this.props;

    if (window.confirm("Do you sure that you want delete this Listing?")) {
      axios
        .post(`/api/delete-listing/${listing.slug}`)
        .then((res) => {
          Store.dispatch(DELETE_LISTING(listing.slug));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  START_PAUSE_LISTING() {
    const { listing } = this.props;

    let q = "";
    if (listing.status == "paused")
      q = "Are you sure you want to make this listing public again?";
    else q = "Are you sure you want to pause this listing?";

    if (window.confirm(q)) {
      axios
        .post(`/api/pause-listing/${listing.slug}`)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          window.alert(err.response.data);
        });
    }
  }

  render() {
    const { openedSettingsMenu } = this.state;
    const { listing, currency } = this.props;

    return (
      <Link className="MyListingCard trlGlobal" to={`/listing/${listing.slug}`}>
        <div>
          <div
            onClick={this.openMenuSettings}
            ref={(node) => (this.containerSettings = node)}
            className="settings"
          >
            <MdSettings size="20" />
            <ul
              className={classnames({
                dropdownOpt: true,
                active: openedSettingsMenu ? true : false,
              })}
            >
              {listing.status == "published" ? (
                <li
                  className="trl MyListingCard pauseListing"
                  onClick={this.START_PAUSE_LISTING}
                >
                  Pause listing
                </li>
              ) : null}
              {listing.status == "paused" ? (
                <li
                  className="trl MyListingCard publishListing"
                  onClick={this.START_PAUSE_LISTING}
                >
                  Publish listing
                </li>
              ) : null}
              <li className="link">
                <Link
                  className="trl MyListingCard editListing"
                  to={`/edit-listing/${listing.slug}`}
                >
                  Edit listing
                </Link>
              </li>
              {/* <li onClick={this.DELETE_LISTING}>Delete listing</li> */}
            </ul>
          </div>

          <span className={`trl MyListingCard status ${listing.status}`}>
            {listing.status}
          </span>

          <ShowPrice pricePerHour={listing.pricePerHour} currency={currency} />

          <h4>{listing.title}</h4>

          <p className="name">{listing.user.name}</p>
          {listing.reviews.length > 0 ? (
            <StarRatings rating={listing.rating / listing.reviews.length} />
          ) : (
            <small>
              <i className="trl MyListingCard noReviewsYet">No reviews yet</i>
            </small>
          )}

          <ul>
            <li>
              <FiMapPin size="15" /> {listing.location}
            </li>
            <li>
              <FiGlobe size="15" /> {listing.languages.join(", ")}
            </li>
            <li>
              <FiSmartphone size="17" />{" "}
              {listing.connectionPreferences.join(", ")}
            </li>
          </ul>
        </div>
      </Link>
    );
  }
}

export default MyListing;
