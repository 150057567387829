import React, { Component } from "react";

import "./sass/Separator.scss";

class Separator extends Component {
  render() {
    const { title, noPadding, className } = this.props;

    return (
      <div style={{ padding: noPadding ? "0" : "11px" }} className="Separator">
        <h3 className={className}>{title}</h3>
      </div>
    );
  }
}

export default Separator;
