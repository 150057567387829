import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import Store from "./redux/store";
import Mentory from "./Mentory.jsx";

const domNode = document.getElementById("root");
const root = createRoot(domNode);
root.render(
  <Provider store={Store}>
    <Mentory />
  </Provider>
);
