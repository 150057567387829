import getValues from "./getValues";

export default (thisReact, moreValues) => {
  //inputs Value
  const {
    listingTitle,
    location,
    listingBio,
    languages,
    connectionPreferences,
    linkedinProfile,
    paidListing,
    services,
    pricePerHour,
    userPhoto,
    listingBioInPlainText,
  } = getValues(thisReact);

  let formData = new FormData();

  if (userPhoto) formData.append("userPhoto", userPhoto.File);
  formData.append(
    "data",
    JSON.stringify({
      listingTitle,
      location,
      listingBio,
      listingBioInPlainText,
      languages,
      connectionPreferences,
      linkedinProfile,
      paidListing,
      services,
      pricePerHour,

      ...moreValues,
    }),
  );

  return formData;
};
