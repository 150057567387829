const defaultStateUser = null;

function reducerUser(state = defaultStateUser, action) {
  let userNow = { ...state };

  switch (action.type) {
    case "UPDATE_USER":
      //let userUpdate = { ...state };
      let userUpdate = action.userInfo;

      return userUpdate;

      break;
    case "UPDATE_USER_VALUE":
      if (userNow.hasOwnProperty(action.value)) {
        if (action.act == "ADD") {
          userNow[action.value].push(action.newData);

          return userNow;
        }
      } else alert("ERROR ON REDUCER!");

      break;

    case "DELETE_LISTING":
      userNow.myListings = userNow.myListings.filter(
        (listing) => listing.slug != action.slug,
      );

      return userNow;

      break;

    //Favourites start /-/
    case "REMOVE_FAVOURITE":
      userNow.myFavourites = userNow.myFavourites.filter(
        (listingFavourite) => listingFavourite.slug != action.slug,
      );

      return userNow;

      break;
    //Favourites end /-/

    //Manage cards start /-/
    case "UPDATE_CARDS":
      userNow.cards = action.allCards;

      return userNow;
    //Manage cards end /-/

    //Manage sessions
    case "PUSH_SESSION":
      var menteeORmentor = action.menteeORmentor;
      userNow.mySessions[menteeORmentor].push(action.session);

      return userNow;

    case "UPDATE_SESSION":
      userNow.mySessions = action.mySessions;

      return userNow;

    case "REMOVE_SESSION":
      var menteeORmentor = action.menteeORmentor;
      userNow.mySessions[menteeORmentor] = userNow.mySessions[
        menteeORmentor
      ].filter((session) => session.id != action.id);

      return userNow;

    default:
      return state;

      break;
  }
}

export default reducerUser;
