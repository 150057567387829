import React, { Component } from "react";

//Form
import Input from "../../UI_Elements/Form/Input";
import InputTag from "../../UI_Elements/Form/InputTag";
import InputDate from "../../UI_Elements/Form/InputDate";
import Dropdown from "../../UI_Elements/Form/Dropdown";
import Location from "../../UI_Elements/Form/Location";

//Components
import Timeline from "../../UI_Elements/Timeline";
import MentoryBox from "../../UI_Elements/MentoryBox";
import Response from "../../UI_Elements/Response";
import showResponse from "../../UI_Elements/Response/showResponse";
import Preloader from "../../UI_Elements/Preloader";
import Modal from "../../UI_Elements/Modal";

//Assets
import { IoIosArrowDown } from "react-icons/io";

//Redux
import { connect } from "react-redux";

import { getCodes, checkCode } from "../../inc/data/Currencies";
import moment from "moment-timezone";
import axios from "axios";

class AboutYou extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateOfBirth:
        props.user &&
        props.user.dateOfBirth &&
        props.user.dateOfBirth.trim() != ""
          ? new Date(props.user.dateOfBirth)
          : null,

      addExperienceFrom: null,
      addExperienceTo: null,

      addEducationFrom: null,
      addEducationTo: null,

      workExperience: props.user.workExperience
        ? props.user.workExperience
        : [],
      education: props.user.education ? props.user.education : [],
      userPhoto: null,
      currency: props.user.currency.trim() != "" ? props.user.currency : null,

      //Erros
      errorFirstName: "",
      errorSurname: "",
      //errorEmail: '',
      errorCurrency: "",
      errorLocation: "",
      errorGender: "",
      errorDateOfBirth: "",
      errorWorkExperience: "",
      errorEducation: "",

      //save changes
      saveChangesAboutYou: false,
    };

    this.UPDATE_USER = this.UPDATE_USER.bind(this);
    this.addNewExperience = this.addNewExperience.bind(this);
    this.addNewEducation = this.addNewEducation.bind(this);

    this.addEducationModal = React.createRef();
    this.addExperienceModal = React.createRef();
    this.addSkillsModal = React.createRef();

    this.firstName = React.createRef();
    this.surname = React.createRef();
    //this.email = React.createRef();
    this.location = React.createRef();
    this.gender = React.createRef();
    this.languages = React.createRef();
    this.bio = React.createRef();

    //New experience
    this.job = React.createRef();
    this.company = React.createRef();

    //New education
    this.degree = React.createRef();
    this.school = React.createRef();
  }

  addNewExperience(e) {
    e.preventDefault();

    let job = this.job.current.state.inputValue,
      company = this.company.current.state.inputValue,
      addExperienceFrom = this.state.addExperienceFrom,
      addExperienceTo = this.state.addExperienceTo;

    let workExperience = this.state.workExperience;

    //Validate
    if (job.trim() == "" || company.trim() == "") {
      return showResponse(
        "addExperienceError",
        "Review data introduced in Job and Company",
      );
    }

    let from_Moment = moment(addExperienceFrom).format("MMM, YYYY");
    let to_Moment = moment(addExperienceTo).isValid()
      ? moment(addExperienceTo).format("MMM, YYYY")
      : "Currently";

    if (new Date(from_Moment) - new Date(to_Moment) > 0) {
      return showResponse(
        "addExperienceError",
        "The chosen dates are not valid.",
      );
    }

    workExperience.push({
      title: job,
      description: company,
      date: `${from_Moment} - ${to_Moment}`,
    });

    //Organize per date
    workExperience.sort(function (a, b) {
      let aDate1 = a.date.split(" - ")[0];
      let aDate2 = a.date.split(" - ")[1];

      let bDate1 = b.date.split(" - ")[0];
      let bDate2 = b.date.split(" - ")[1];

      if (new Date(aDate1) - new Date(bDate1) == 0) {
        return new Date(aDate2) - new Date(bDate2);
      } else {
        return new Date(aDate1) - new Date(bDate1);
      }
    });
    /*******************/

    this.addExperienceModal.current._openCloseModal(false);

    this.job.current._setValue("");
    this.company.current._setValue("");

    this.setState({
      workExperience,
      addExperienceFrom: "",
      addExperienceTo: "",
    });
  }

  addNewEducation(e) {
    e.preventDefault();

    let degree = this.degree.current.state.inputValue,
      school = this.school.current.state.inputValue,
      addEducationFrom = this.state.addEducationFrom,
      addEducationTo = this.state.addEducationTo;

    var education = this.state.education;

    //Validate
    if (degree.trim() == "" || school.trim() == "") {
      return showResponse(
        "addEducationError",
        "Review data introduced in degree and school",
      );
    }

    let from_Moment = moment(addEducationFrom);
    let to_Moment = moment(addEducationTo);

    if (
      new Date(from_Moment.format("MMM, YYYY")) -
        new Date(to_Moment.format("MMM, YYYY")) >
      0
    ) {
      return showResponse(
        "addEducationError",
        "The chosen dates are not valid.",
      );
    }
    if (!from_Moment.isValid() || !to_Moment.isValid()) {
      return showResponse(
        "addEducationError",
        "The chosen dates are not valid.",
      );
    }

    education.push({
      title: degree,
      description: school,
      date: `${from_Moment.format("MMM, YYYY")} - ${to_Moment.format("MMM, YYYY")}`,
    });

    //Organize per date
    education.sort(function (a, b) {
      let aDate1 = a.date.split(" - ")[0];
      let aDate2 = a.date.split(" - ")[1];

      let bDate1 = b.date.split(" - ")[0];
      let bDate2 = b.date.split(" - ")[1];

      if (new Date(aDate1) - new Date(bDate1) == 0) {
        return new Date(aDate2) - new Date(bDate2);
      } else {
        return new Date(aDate1) - new Date(bDate1);
      }
    });
    /*******************/

    this.addEducationModal.current._openCloseModal(false);

    this.setState({ education, addEducationFrom: "", addEducationTo: "" });

    this.degree.current._setValue("");
    this.school.current._setValue("");
  }

  UPDATE_USER(e) {
    e.preventDefault();

    this.setState({ saveChangesAboutYou: true });

    //inputs Value
    var firstName = this.firstName.current.state.inputValue,
      surname = this.surname.current.state.inputValue,
      //email           = this.email.current.state.inputValue,
      currency = this.state.currency,
      location = this.location.current.state.addressFromGoogleMapsPlace,
      gender = this.gender.current.state.dropdownValue,
      dateOfBirth = this.state.dateOfBirth,
      languages = this.languages.current.state.tagsListSelected,
      bio = this.bio.current.state.inputValue,
      workExperience = this.state.workExperience,
      education = this.state.education;

    const formData = new FormData();

    if (this.props.userPhoto) {
      formData.append("userPhoto", this.props.userPhoto.File);
    }

    formData.append(
      "data",
      JSON.stringify({
        name: firstName,
        surname,
        //email,
        currency,
        location,
        gender,
        languages,
        bio,
        dateOfBirth,
        workExperience,
        education,
      }),
    );

    //Validate
    let firstNameIsValid = RegExp(
      '[A-Z][a-zA-Z][^#&<>"~;$^%{}?]{1,10}$',
      "g",
    ).test(firstName);
    let surnameIsValid = RegExp(
      '[A-Z][a-zA-Z][^#&<>"~;$^%{}?]{1,10}$',
      "g",
    ).test(surname);
    //let emailIsValid           = RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}', 'g').test(email);
    let locationIsValid = this.location.current._validateLocation();
    let genderIsValid =
      gender == "Female" || gender == "Male" || gender == "Other";
    //let dateOfBirthIsValid     = dateOfBirth.trim() != '';
    //let workExperienceIsValid  = workExperience.length != 0;
    //let educationIsValid       = education.length != 0;
    let currencyIsValid = checkCode(currency);

    //First Name
    if (!firstNameIsValid) {
      showResponse("updateUserError", "please fill in required fields");

      this.setState({
        saveChangesAboutYou: false,
        errorFirstName: "This field is not valid",
      });
    } else this.setState({ errorFirstName: "" });

    //Surname
    if (!surnameIsValid) {
      showResponse("updateUserError", "please fill in required fields");

      this.setState({
        saveChangesAboutYou: false,
        errorSurname: "This field is not valid",
      });
    } else this.setState({ errorSurname: "" });

    //Email
    //if( !emailIsValid ){
    //   showResponse('updateUserError', 'please fill in required fields');
    //
    //   this.setState({saveChangesAboutYou: false, errorEmail: 'This field is not valid'})
    //}else this.setState({errorEmail: ''})

    //Currency
    if (!currencyIsValid) {
      showResponse("updateUserError", "please fill in required fields");

      this.setState({
        saveChangesAboutYou: false,
        errorCurrency: "Select value from dropdown",
      });
    } else this.setState({ errorCurrency: "" });

    //Location
    if (!locationIsValid) {
      showResponse("updateUserError", "please fill in required fields");

      this.setState({
        saveChangesAboutYou: false,
        errorLocation: "This field is not valid",
      });
    } else this.setState({ errorLocation: "" });

    //Gender
    //if( !genderIsValid ){
    //   showResponse('updateUserError', 'please fill in required fields');
    //
    //   this.setState({saveChangesAboutYou: false, errorGender: 'This field is not valid'})
    //}else this.setState({errorGender: ''})

    if (
      firstNameIsValid &&
      surnameIsValid &&
      //emailIsValid &&
      currencyIsValid &&
      locationIsValid
    ) {
      axios
        .post("/api/update-user", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          //Store.dispatch( UPDATE_USER(res.data) );

          showResponse("updateUserSuccess");
          this.setState({ saveChangesAboutYou: false });
        })
        .catch((err) => {
          console.log(err.reponse);
          showResponse("updateUserError", err.response.data);
        });
    }
  }

  render() {
    const {
      dateOfBirth,
      addExperienceFrom,
      addExperienceTo,
      addEducationFrom,
      addEducationTo,
      workExperience,
      education,
      saveChangesAboutYou,
    } = this.state;
    const { user } = this.props;

    //Errors
    const {
      errorFirstName,
      errorSurname,
      //errorEmail,
      errorCurrency,
      errorLocation,
      errorGender,
      errorDateOfBirth,
    } = this.state;

    return (
      <React.Fragment>
        <article id="about-you">
          <Response
            id="updateUserSuccess"
            type="success"
            title="Profile updated"
            description="Your profile was successfully updated"
            btnText="Close"
            btnAction="close"
          />

          <Response
            id="updateUserError"
            type="error"
            title="Something went wrong"
            description=""
            btnText="Close"
            btnAction="close"
          />

          <form className="simple" onSubmit={this.UPDATE_USER}>
            <MentoryBox title="About you">
              <Input
                id="firstName"
                error={errorFirstName}
                ref={this.firstName}
                value={user.name}
                label="First Name*"
              />
              <Input
                id="surname"
                error={errorSurname}
                ref={this.surname}
                value={user.surname}
                label="Surname*"
              />
              <Dropdown
                description="Select your currency before creating or booking a session"
                className="dropdownCurrency"
                onChange={(currency) => this.setState({ currency })}
                values={getCodes()}
                label="Currency"
                placeholder="Currency"
                error={errorCurrency}
                value={user.currency}
              />
              {/* <Input error={errorEmail} ref={this.email} value={user.email} label="Email*" /> */}
              <Location
                error={errorLocation}
                ref={this.location}
                value={user.location}
                label="Where are you based?"
              />
              <Dropdown
                className="dropdownGender"
                error={errorGender}
                ref={this.gender}
                value={user.gender}
                label="Gender"
                values={["Male", "Female", "Other"]}
                description="Not visible to other users."
              />
              <InputDate
                error={errorDateOfBirth}
                label="Date of birth"
                icon={<IoIosArrowDown />}
                selected={dateOfBirth}
                onSelect={(newDate) => this.setState({ dateOfBirth: newDate })}
                description="Not visible to other users."
              />
              <InputTag
                tagsSelected={user.languages}
                ref={this.languages}
                tags={this.props.settings.languagesName}
                label="Languages that you are fluent at"
                icon={<IoIosArrowDown />}
              />
              <Input
                value={user.bio}
                ref={this.bio}
                className="profileBio"
                textarea
                label="Profile bio"
              />
            </MentoryBox>

            <MentoryBox title="Experience" id="experience">
              <div>
                <Timeline
                  title="Work Experience"
                  items={workExperience}
                  remove
                  removed={(items) => this.setState({ workExperience: items })}
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    this.addExperienceModal.current._openCloseModal(true);
                  }}
                  className="btn white trl settings aboutYou plusWorkExperience"
                >
                  + Work Experience
                </button>
              </div>
              <div>
                <Timeline
                  title="Education"
                  items={education}
                  remove
                  removed={(items) => this.setState({ education: items })}
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    this.addEducationModal.current._openCloseModal(true);
                  }}
                  className="btn white trl settings aboutYou plusEducation"
                >
                  + Education
                </button>
              </div>
            </MentoryBox>

            <div className="actions flex">
              {saveChangesAboutYou ? (
                <Preloader />
              ) : (
                <button className="btn large trl settings aboutYou saveChanges">
                  save changes
                </button>
              )}
              <p
                className="trl settings aboutYou cancel"
                onClick={() => window.location.reload()}
              >
                cancel
              </p>
            </div>
          </form>

          {/* MODAL */}
          <Response
            id="addExperienceError"
            type="error"
            title="Error! review the entered data"
            description=""
            btnText="Try again"
            btnAction="close"
          />
          <Modal
            title="Add experience"
            ref={this.addExperienceModal}
            className="addExperienceModal"
            iconToClose
          >
            <form onSubmit={this.addNewExperience} className="simple">
              <Input ref={this.job} label="Job*" />
              <Input ref={this.company} label="Company*" />
              <InputDate
                showMonthYearPicker
                dateFormat="MMMM, yyyy"
                label="From*"
                icon={<IoIosArrowDown />}
                selected={addExperienceFrom}
                onSelect={(newDate) =>
                  this.setState({ addExperienceFrom: newDate })
                }
              />
              <InputDate
                showMonthYearPicker
                dateFormat="MMMM, yyyy"
                label="To"
                icon={<IoIosArrowDown />}
                selected={addExperienceTo}
                onSelect={(newDate) =>
                  this.setState({ addExperienceTo: newDate })
                }
              />
              <button className="btn">add new experience</button>
            </form>
          </Modal>

          <Response
            id="addEducationError"
            type="error"
            title="Error! review the entered data"
            description=""
            btnText="Try again"
            btnAction="close"
          />
          <Modal
            title="Add education"
            ref={this.addEducationModal}
            className="addEducationModal"
            iconToClose
          >
            <form onSubmit={this.addNewEducation} className="simple">
              <Input ref={this.degree} label="Degree*" />
              <Input ref={this.school} label="School*" />
              <InputDate
                showMonthYearPicker
                dateFormat="MMMM, yyyy"
                label="From*"
                icon={<IoIosArrowDown />}
                selected={addEducationFrom}
                onSelect={(newDate) =>
                  this.setState({ addEducationFrom: newDate })
                }
              />
              <InputDate
                showMonthYearPicker
                dateFormat="MMMM, yyyy"
                label="To*"
                icon={<IoIosArrowDown />}
                selected={addEducationTo}
                onSelect={(newDate) =>
                  this.setState({ addEducationTo: newDate })
                }
              />
              <button className="btn">add new education</button>
            </form>
          </Modal>
        </article>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(AboutYou);
