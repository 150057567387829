import categories from "../../inc/data/categories.json";
import connectionPreferences from "../../inc/data/connectionPreferences.json";
import { getLanguagesName } from "../../inc/data/Languages";
import services from "../../inc/data/services.json";

import axios from "axios";

var defaultStateSettings = {
  userLogged: false,
  userType:
    (window.localStorage.getItem("userType") &&
      window.localStorage.getItem("userType") == "Mentee") ||
    (window.localStorage.getItem("userType") &&
      window.localStorage.getItem("userType") == "Mentor")
      ? window.localStorage.getItem("userType")
      : "Mentee",
  currentUsername: window.localStorage.getItem("currentUsername")
    ? window.localStorage.getItem("currentUsername")
    : null,

  allUsersStatus: {},

  //Data
  categories,
  connectionPreferences,
  languagesName: getLanguagesName(),
  companies: ["Apple", "Airbus", "Amazon", "Coach", "Balenciaga", "Tekkel"],

  contactSelectedOnChat: null,

  //page slug
  publicProfile: "me",

  //Notifications
  notifications: window.localStorage.getItem("notifications")
    ? JSON.parse(window.localStorage.getItem("notifications"))
    : [],

  services,
  PublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
};

function reducerUser(state = defaultStateSettings, action) {
  var newSettings = { ...state };

  switch (action.type) {
    case "SETTINGS":
      if (newSettings.hasOwnProperty(action.setting)) {
        //Update userType?
        if (action.setting == "userType") {
          window.localStorage.setItem(
            "userType",
            action.newSettings.userSwitch,
          );
          window.localStorage.setItem(
            "currentUsername",
            action.newSettings.username,
          );

          newSettings["userType"] = action.newSettings.userSwitch;
          newSettings["currentUsername"] = action.newSettings.username;
        } else {
          newSettings[action.setting] = action.newSettings;
        }
      } else alert("ERROR ON REDUCER!");

      return newSettings;

      break;

    case "NOTIFICATIONS":
      if (action.act == "add") {
        newSettings.notifications.push(action.chatID);

        //Set on localStorage
        window.localStorage.setItem(
          "notifications",
          JSON.stringify(newSettings.notifications),
        );
      } else if (action.act == "del") {
        newSettings.notifications = newSettings.notifications.filter(
          (chatID) => action.chatID != chatID,
        );

        //Set on localStorage
        window.localStorage.setItem(
          "notifications",
          JSON.stringify(newSettings.notifications),
        );
      }

      return newSettings;

      break;

    default:
      return state;

      break;
  }
}

export default reducerUser;
