//Redux
import Store from "../redux/store";
import { REMOVE_FAVOURITE } from "../redux/actions";

import axios from "axios";

export default (slug) => {
  if (
    window.confirm(
      "Are you sure you want to remove this listing from your favorites? You will not be able to undo this action.",
    )
  ) {
    axios
      .post(`/api/remove-favourite/${slug}`)
      .then((res) => {
        Store.dispatch(REMOVE_FAVOURITE(slug));
      })
      .catch((err) => {
        console.log(err.response);
      });
  }
};
