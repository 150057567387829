import React, { Component } from "react";

import ShowPrice from "../../inc/ShowPrice";
import classnames from "classnames";
import defaultPhoto from "../../assets/default.png";

//Redux
import { connect } from "react-redux";

class ProfileCard extends Component {
  render() {
    const { listing, mentor } = this.props;

    return (
      <React.Fragment>
        <div className="profileCard">
          <ShowPrice
            pricePerHour={listing.pricePerHour}
            currency={mentor.currency}
          />

          <span
            style={{
              backgroundImage: `url(${mentor.userPhoto.trim() != "" ? mentor.userPhoto : defaultPhoto})`,
            }}
            className={classnames({
              photo: true,
              on: this.props.settings.allUsersStatus[mentor.username]
                ? this.props.settings.allUsersStatus[mentor.username].online
                : false,
            })}
          ></span>

          <p className="name">
            {mentor.name} {mentor.surname}
          </p>
        </div>

        <div className="profileCardMobile">
          <div className="flex">
            <span
              style={{
                backgroundImage: `url(${mentor.userPhoto.trim() != "" ? mentor.userPhoto : defaultPhoto})`,
              }}
              className={classnames({
                photo: true,
                on: this.props.settings.allUsersStatus[mentor.username]
                  ? this.props.settings.allUsersStatus[mentor.username].online
                  : false,
              })}
            ></span>

            <div>
              <p className="name">
                {mentor.name} {mentor.surname}
              </p>
              <ShowPrice
                pricePerHour={listing.pricePerHour}
                currency={mentor.currency}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProp = (state) => {
  return state;
};
export default connect(mapStateToProp)(ProfileCard);
