const UPDATE_USER_TYPE = "UPDATE_USER";
const UPDATE_USER = (userInfo) => {
  return {
    type: UPDATE_USER_TYPE,
    userInfo: userInfo,
  };
};

const UPDATE_USER_VALUE_TYPE = "UPDATE_USER_VALUE";
const UPDATE_USER_VALUE = (value, newData, act) => {
  return {
    type: UPDATE_USER_VALUE_TYPE,
    value: value,
    newData: newData,
    act: act,
  };
};

const DELETE_LISTING_TYPE = "DELETE_LISTING";
const DELETE_LISTING = (slug) => {
  return {
    type: DELETE_LISTING_TYPE,
    slug,
  };
};

const REMOVE_FAVOURITE_TYPE = "REMOVE_FAVOURITE";
const REMOVE_FAVOURITE = (slug) => {
  return {
    type: REMOVE_FAVOURITE_TYPE,
    slug,
  };
};

//Manage cards
const UPDATE_CARDS_TYPE = "UPDATE_CARDS";
const UPDATE_CARDS = (allCards) => {
  return {
    type: UPDATE_CARDS_TYPE,
    allCards,
  };
};

//Manage sessions
const PUSH_SESSION_TYPE = "PUSH_SESSION";
const PUSH_SESSION = (session, menteeORmentor) => {
  return {
    type: PUSH_SESSION_TYPE,
    session,
    menteeORmentor,
  };
};
const UPDATE_SESSION_TYPE = "UPDATE_SESSION";
const UPDATE_SESSION = (mySessions) => {
  return {
    type: UPDATE_SESSION_TYPE,
    mySessions,
  };
};
const REMOVE_SESSION_TYPE = "REMOVE_SESSION";
const REMOVE_SESSION = (id, menteeORmentor) => {
  return {
    type: REMOVE_SESSION_TYPE,
    id,
    menteeORmentor,
  };
};

//Settings
const SETTINGS_TYPE = "SETTINGS";
const SETTINGS = (settings, newSettings) => {
  return {
    type: SETTINGS_TYPE,
    setting: settings,
    newSettings: newSettings,
  };
};

const NOTIFICATIONS_TYPE = "NOTIFICATIONS";
const NOTIFICATIONS = (chatID, act) => {
  return {
    type: NOTIFICATIONS_TYPE,
    chatID,
    act,
  };
};

export {
  REMOVE_SESSION,
  UPDATE_SESSION,
  PUSH_SESSION,
  UPDATE_CARDS,
  REMOVE_FAVOURITE,
  DELETE_LISTING,
  UPDATE_USER_VALUE,
  UPDATE_USER,
  SETTINGS,
  NOTIFICATIONS,
};
