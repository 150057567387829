import React, { Component } from "react";

import axios from "axios";

import "./sass/SignUp.scss";

import Response from "../../UI_Elements/Response";
import showResponse from "../../UI_Elements/Response/showResponse";

class SignUp extends Component {
  constructor() {
    super();

    this.getLead = this.getLead.bind(this);

    this.state = {
      language: window.navigator.userLanguage || window.navigator.language,
    };
  }

  getLead(e) {
    e.preventDefault();

    let email = document.querySelector(
      "section.SignUpSection form.stylish input",
    );

    if (!email.value.validateEmail()) return showResponse("ErrorSignUp");

    axios
      .post(`/api/marketing/lead/${email.value}`, {
        language: this.state.language,
      })
      .then((res) => {
        showResponse("SuccessSignUp");
        email.value = "";
      })
      .catch((err) =>
        showResponse(
          "ErrorSignUp",
          "Try again in a few minutes",
          "Something went wrong.",
        ),
      );
  }

  componentDidMount() {
    console.log("language: ", this.state.language);
  }

  render() {
    return (
      <section className="SignUpSection trlGlobal">
        <Response
          id="ErrorSignUp"
          type="error"
          title="Email not valid"
          description=""
          btnText="Try again"
          btnAction="close"
        />

        <Response
          id="SuccessSignUp"
          type="success"
          title="Thanks for subscribing"
          description=""
          btnText="Close"
          btnAction="close"
        />

        <div className="content">
          <h2 className="trl SignUpSection title">Growth starts here.</h2>
          <p className="trl SignUpSection subtitle">
            Informative content to help you grow right in your inbox. Best of
            the best. Once per month. No spam.
          </p>
          <form className="stylish" onSubmit={this.getLead}>
            <div>
              <p>
                <input
                  className="trl SignUpSection yourEmail"
                  type="email"
                  placeholder="Your email"
                />
              </p>
              <button
                type="submit"
                className="btn large trl SignUpSection signUp"
              >
                sign up
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default SignUp;
