export default (e, thisReact) => {
  let File = e.target.files[0];

  //check size
  if (File.size / 1000 > 4000) {
    window.alert("Error! You can't upload image above 4MG");
    return;
  }

  //Type file
  if (File.type != "image/jpeg" && File.type != "image/png") {
    window.alert("Error! Only JPG and PNG please");
    return;
  }

  thisReact.setState({
    userPhoto: {
      url: URL.createObjectURL(File),
      File,
    },
  });
};
