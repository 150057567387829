import React, { Component } from "react";

class Preloader extends Component {
  render() {
    const { size, style, page } = this.props;

    return (
      <div
        style={style}
        className={`preloader-wrapper ${size ? size : "small"} ${page ? "preloaderPage" : null} active`}
      >
        <div className="spinner-layer spinner-blue-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Preloader;
