export default (checked, index, value, thisReact) => {
  const { services } = thisReact.state;
  var newSessionArray = [...services];

  if (checked === null && !newSessionArray[index].active) return;

  if (checked === null) {
    if (!newSessionArray[index].selected) newSessionArray[index].selected = [];

    if (newSessionArray[index].selected.includes(value)) {
      newSessionArray[index].selected = newSessionArray[index].selected.filter(
        (e) => value != e,
      );
    } else {
      newSessionArray[index].selected.push(value);
    }
  } else if (!checked) {
    newSessionArray[index].active = false;
    newSessionArray[index].selected = [];
  } else {
    newSessionArray[index].active = true;
  }

  thisReact.setState({ services: newSessionArray });
};
