import React, { Component } from "react";

import "./sass/Settings.scss";

//Components
import Nav from "../../components/Menu";
import Footer from "../../components/Footer";
import Response from "../../UI_Elements/Response";

//Redux
import { connect } from "react-redux";

//Views
import AboutYou from "./AboutYou";
import Billing from "./Billing";
import ChangePassword from "./ChangePassword";

//Assets
import defaultPhoto from "../../assets/default.png";
import { MdKeyboardArrowRight } from "react-icons/md";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: "#about-you",
      userPhoto: null,
    };

    this._updateView = this._updateView.bind(this);

    //Uplaod image
    this.uploadImage = this.uploadImage.bind(this);
    this.photoUser = React.createRef();
  }

  _updateView(e) {
    this.setState({
      view: window.location.hash == "" ? "#about-you" : window.location.hash,
    });
  }

  componentDidMount() {
    window.addEventListener("hashchange", this._updateView);
    this._updateView();
  }

  uploadImage(e) {
    let File = e.target.files[0];

    //check size
    if (File.size / 1000 > 4000) {
      window.alert("Error! You can't upload image above 4MG");
      return;
    }

    //Type file
    if (File.type != "image/jpeg" && File.type != "image/png") {
      window.alert("Error! Only JPG and PNG please");
      return;
    }

    this.setState({
      userPhoto: {
        url: URL.createObjectURL(File),
        File,
      },
    });
  }

  render() {
    const { view, userPhoto } = this.state;

    const { user } = this.props;

    //Set a photo
    var currentPhotoUser = "";
    if (userPhoto) {
      currentPhotoUser = userPhoto.url;
    } else if (user.userPhoto && user.userPhoto.trim() != "") {
      currentPhotoUser = user.userPhoto;
    } else {
      currentPhotoUser = defaultPhoto;
    }

    return (
      <div id="Settings">
        <Response
          id="errorResponse"
          type="error"
          title=""
          description=""
          btnText="Close"
          btnAction="close"
        />

        <Nav style="stylish" />

        <section>
          <div className="content flex">
            <aside className="trlGlobal">
              <span
                onClick={() => this.photoUser.current.click()}
                style={{ backgroundImage: `url(${currentPhotoUser})` }}
                className="photo"
              >
                <input
                  onChange={this.uploadImage}
                  ref={this.photoUser}
                  type="file"
                />
              </span>
              <p className="subtitle">
                {user.name} {user.surname}
              </p>

              <ul>
                <li>
                  <a
                    className={view == "#about-you" ? "active" : null}
                    href="#about-you"
                  >
                    <span className="trl menuAside aboutYou">About you</span>{" "}
                    <MdKeyboardArrowRight size="22" />
                  </a>
                </li>
                <li>
                  <a
                    className={view == "#billing" ? "active" : null}
                    href="#billing"
                  >
                    <span className="trl menuAside billing">Billing</span>{" "}
                    <MdKeyboardArrowRight size="22" />
                  </a>
                </li>
                <li>
                  <a
                    className={view == "#change-password" ? "active" : null}
                    href="#change-password"
                  >
                    <span className="trl menuAside changePassword">
                      Change password
                    </span>{" "}
                    <MdKeyboardArrowRight size="22" />
                  </a>
                </li>
              </ul>
            </aside>

            {/* Views */}
            {view == "#about-you" ? <AboutYou userPhoto={userPhoto} /> : null}
            {view == "#billing" ? <Billing /> : null}
            {view == "#change-password" ? <ChangePassword /> : null}
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(Settings);
