import React, { Component } from "react";

import classnames from "classnames";

class RadioButtom extends Component {
  constructor() {
    super();

    this.state = {
      valueSelected: "",
    };

    this.onChangeRadio = this.onChangeRadio.bind(this);
  }

  onChangeRadio(e, position) {
    if (this.props.onChange) this.props.onChange(e.target.value, position);
  }

  render() {
    const { title, labels, checked, type, values, trl } = this.props;

    return (
      <div className="RadioButtom">
        <p
          className={`trl radioButtom ${window.TranslateApp.clearString(title)}`}
        >
          {title}
        </p>
        <div
          className={classnames({
            container: true,
            h: type == "h" ? true : false,
            v: type == "v" ? true : false,
          })}
        >
          {labels.map((label, i) => {
            return (
              <p key={`radio_button_${i}`}>
                <label>
                  <input
                    onChange={(e) => {
                      this.setState({
                        valueSelected: values ? values[i] : label,
                      });

                      this.onChangeRadio(e, i);
                    }}
                    value={values ? values[i] : label}
                    defaultChecked={checked && checked == i ? true : false}
                    className="with-gap"
                    type="radio"
                    name="radio_buttom"
                  />
                  <span
                    className={
                      trl
                        ? `trl radioButton ${values ? window.TranslateApp.clearString(values[i]) : i}`
                        : ""
                    }
                    style={{ display: "flex" }}
                  >
                    {label}
                  </span>
                </label>
              </p>
            );
          })}
        </div>
      </div>
    );
  }
}

export default RadioButtom;
