import React, { Component } from "react";

//SASS
import "./scss/Listing.scss";

//Components
import Nav from "../../components/Menu";

import StarRatings from "../../UI_Elements/StarRatings";
import Separator from "../../UI_Elements/Separator";
import Timeline from "../../UI_Elements/Timeline";
import Review from "../../components/Review";

import Response from "../../UI_Elements/Response";
import Preloader from "../../UI_Elements/Preloader";

//Assets
import {
  FiGlobe,
  FiMapPin,
  FiSmartphone,
  FiUser,
  FiHeart,
} from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { IoIosHeart } from "react-icons/io";

//Blocks
import RelatedMentor from "./RelatedMentor";
import BookingModal from "./BookingModal";
import SelectCurrencyModal from "../../components/SelectCurrencyModal";
import ProfileCard from "./ProfileCard";

//Redux
import { connect } from "react-redux";
//Actions
import ADD_FAVOURITE from "../../inc/ADD_FAVOURITE";
import REMOVE_FAVOURITE from "../../inc/REMOVE_FAVOURITE";

import axios from "axios";
import { Link } from "react-router-dom";
import convertLinkYoutubeToIframe from "./actions/convertLinkYoutubeToIframe";

class Listing extends Component {
  constructor() {
    super();

    this.state = {
      listing: null,
      mentor: null,
      serviceSelected: null,

      rate: null,
    };

    this.addToContacts = this.addToContacts.bind(this);
    this.requestToBook = this.requestToBook.bind(this);
    this.toggleFavourite = this.toggleFavourite.bind(this);
    this._upgradeFirst = this._upgradeFirst.bind(this);

    this.ModalBookSessionRef = React.createRef();
  }

  _upgradeFirst = () => {
    const { user } = this.props;
    const { userLogged } = this.props.settings;

    if (
      !user ||
      !userLogged ||
      !user.subscription ||
      Object.keys(user.subscription).length == 0
    ) {
      this.props.history.push({
        pathname: "/upgrade",
        state: { back: true, path: window.location.pathname },
      });
      return true;
    }

    return false;
  };

  addToContacts() {
    if (this._upgradeFirst()) return;

    axios
      .put("/api/contacts", {
        mentorUsername: this.state.mentor.username,
        menteeUsername: this.props.user.username,
      })
      .catch((err) => {
        console.log(err.response);
      });

    this.props.history.push("/connect");
  }
  requestToBook(serviceSelected) {
    if (this._upgradeFirst()) return;

    this.setState({ serviceSelected });

    this.ModalBookSessionRef.current._openCloseModal(true);
  }
  toggleFavourite(add) {
    if (this._upgradeFirst()) return;

    if (add) {
      ADD_FAVOURITE(this.state.listing.slug);
    } else {
      REMOVE_FAVOURITE(this.state.listing.slug);
    }
  }

  componentDidMount() {
    axios
      .post(`/api/get-mentor-from-slug/${this.props.match.params.slug}`)
      .then(async (res) => {
        this.setState({
          mentor: res.data.mentor,
          listing: res.data.listing,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }

  render() {
    const { listing, mentor, serviceSelected, rate } = this.state;

    const { user } = this.props;
    const { userLogged } = this.props.settings;

    if (!listing || !mentor) return <Preloader page />;

    return (
      <div id="Listing">
        <Nav style="stylish" />

        <div className="trlGlobal">
          <Response
            id="PaymentError"
            type="error"
            title="Payment Refused"
            description=""
            btnText="Close"
            btnAction="close"
          />

          <Response
            id="PaymentSuccess"
            type="success"
            title="Payment Successful"
            description=""
            btnText="Go to session"
            btnAction="/sessions"
          />

          {userLogged ? (
            <React.Fragment>
              <SelectCurrencyModal />
              <BookingModal
                refModal={this.ModalBookSessionRef}
                mentor={mentor}
                listing={listing}
                serviceSelected={serviceSelected}
              />
            </React.Fragment>
          ) : null}

          <section className="main">
            <div className="content flex">
              <div className="about">
                {window.innerWidth <= 700 ? (
                  <Link className="backToResults" to="/find">
                    <IoIosArrowBack />{" "}
                    <span className="trl eachListing backToResults">
                      Back to results
                    </span>
                  </Link>
                ) : null}

                <h2>{listing.title}</h2>

                <div className="rating">
                  <p className="trl eachListing rating">Rating:</p>
                  {listing.rating > 0 ? (
                    <StarRatings
                      rating={listing.rating / listing.reviews.length}
                    />
                  ) : (
                    <p className="trl eachListing noReviewsYet">
                      No reviews yet
                    </p>
                  )}
                </div>

                <ul>
                  <li>
                    <FiGlobe size="15" /> {listing.location}
                  </li>
                  <li>
                    <FiMapPin size="15" /> {listing.languages.join(", ")}
                  </li>
                  <li>
                    <FiSmartphone size="15" />{" "}
                    {listing.connectionPreferences.join(", ")}
                  </li>
                </ul>

                <article
                  id="mentorDescription"
                  dangerouslySetInnerHTML={{
                    __html: convertLinkYoutubeToIframe(listing.description),
                  }}
                ></article>
                <div className="actions">
                  <ul>
                    {user &&
                    user.myFavourites.find(
                      (listingFavourite) =>
                        listingFavourite.slug == this.props.match.params.slug,
                    ) ? (
                      <li onClick={() => this.toggleFavourite(false)}>
                        <IoIosHeart />{" "}
                        <span className="trl eachListing removeFavourite">
                          Remove favourite
                        </span>
                      </li>
                    ) : (
                      <li onClick={() => this.toggleFavourite(true)}>
                        <FiHeart size="15" />{" "}
                        <span className="trl eachListing addFavourite">
                          Add favourite
                        </span>
                      </li>
                    )}
                    {!user || mentor.username != user.username ? (
                      <li onClick={this.addToContacts}>
                        <FiSend size="15" />{" "}
                        <span className="trl eachListing contactMentor">
                          Contact mentor
                        </span>
                      </li>
                    ) : null}

                    <Link
                      to={`/${this.props.settings.publicProfile}/${mentor.username}`}
                    >
                      <li>
                        <FiUser size="17" />{" "}
                        <span className="trl eachListing viewProfile">
                          View profile
                        </span>
                      </li>
                    </Link>
                  </ul>
                </div>

                <Separator
                  className="trl eachListing services"
                  noPadding
                  title="Services offered"
                />
                <article id="services" className="trlGlobal">
                  {listing.services.map((s) => {
                    if (!s.active) return;

                    return (
                      <div>
                        <p>
                          <span className={`trl global service title ${s.id}`}>
                            {s.title}
                          </span>
                          <span className={`trl global service desc ${s.id}`}>
                            {s.desc}
                          </span>
                        </p>
                        {!user || mentor.username != user.username ? (
                          <button
                            onClick={() => this.requestToBook(s)}
                            className="btn white trl listing requestToBook"
                          >
                            request to book
                          </button>
                        ) : null}
                      </div>
                    );
                  })}
                </article>

                <Separator
                  className="trl eachListing qualifications"
                  noPadding
                  title="Qualifications"
                />
                <div className="qualifications">
                  {listing.workExperience.length > 0 ? (
                    <Timeline
                      title="Work Experience"
                      items={listing.workExperience}
                    />
                  ) : (
                    <p className="trl eachListing noExperienceToDisplay">
                      No experience to display
                    </p>
                  )}
                  {listing.education.length > 0 ? (
                    <Timeline title="Education" items={listing.education} />
                  ) : (
                    <p className="trl eachListing noEducationToDisplay">
                      No education to display
                    </p>
                  )}
                </div>

                <Separator
                  className="trl eachListing reviews"
                  noPadding
                  title="Reviews"
                />
                <div className="reviews">
                  {listing.reviews.length > 0 ? (
                    listing.reviews.map((review, i) => {
                      return <Review key={i} review={review} />;
                    })
                  ) : (
                    <p className="trl eachListing noReviewsYet">
                      No reviews yet
                    </p>
                  )}
                  {/*<button className="btn white">more reviews</button> */}
                </div>
                <br />
                <br />
                <br />
              </div>

              <ProfileCard listing={listing} mentor={mentor} rate={rate} />
            </div>
          </section>

          <RelatedMentor />
        </div>
      </div>
    );
  }
}

const mapStateToProp = (state) => {
  return state;
};
export default connect(mapStateToProp)(Listing);
