import React, { Component } from "react";

import { withRouter } from "react-router";

import Footer from "../../components/Footer";
import Nav from "../../components/Menu";

import "./sass/Page404.scss";

import { connect } from "react-redux";

class Page404 extends Component {
  render() {
    return (
      <div id="Page404">
        <Nav />
        <div>
          <h1>Oops! We can’t find that page...</h1>
          <p className="subtitle">
            We can’t seem to find the page you’re looking for, but we found
            really good mentors for you.
          </p>
          <button
            onClick={() => this.props.history.push("/find")}
            className="btn large"
          >
            view mentors
          </button>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default withRouter(connect(mapStateToProps)(Page404));
