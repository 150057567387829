import React, { Component } from "react";

import "./sass/Modal.scss";

import { MdClose } from "react-icons/md";

import classnames from "classnames";

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: props.open,
    };

    this._closeModal = this._closeModal.bind(this);
    this._openCloseModal = this._openCloseModal.bind(this);
    this._scrollTop = this._scrollTop.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.ModalRef = React.createRef();
  }

  _scrollTop() {
    if (this.ModalRef) this.ModalRef.children[0].scrollTop = 0;
  }

  _closeModal() {
    this._scrollTop();
    this.setState({ opened: false });
  }

  _openCloseModal(opened) {
    this._scrollTop();
    this.setState({ opened });
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick(e) {
    const { lock } = this.props;

    if (this.ModalRef != e.target) return;

    if (!lock) {
      this._scrollTop();
      this.setState({ opened: false });
    }
  }

  render() {
    const { children, title, className, iconToClose } = this.props;
    const { opened } = this.state;

    return (
      <div
        ref={(node) => (this.ModalRef = node)}
        className={classnames({
          Modal: true,
          [className]: className ? true : false,
          active: opened,
        })}
      >
        <div
          className={classnames({
            container: true,
            noHead: !title || title == "" ? true : false,
          })}
        >
          <div className="flex">
            <p
              className={`trl title modal ${window.TranslateApp.clearString(title)}`}
            >
              {title}
            </p>
            {iconToClose ? (
              <span onClick={this._closeModal}>
                <MdClose size="25" />
              </span>
            ) : null}
          </div>
          <div className="wrap">{children}</div>
        </div>
      </div>
    );
  }
}

export default Modal;
