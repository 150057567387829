import axios from "axios";

export function askToShowNotifications_NativePrompt() {
  console.log("askToShowNotifications_NativePrompt called!");
  window.OneSignal.showNativePrompt();
}

export function askToShowNotifications_BeaultifulOption() {
  window.OneSignal.push(function () {
    window.OneSignal.showSlidedownPrompt();
  });
}

export function isPushNotificationsEnabled() {
  window.OneSignal.isPushNotificationsEnabled(function (isEnabled) {
    console.log("isEnabled: ", isEnabled);
    if (!isEnabled) askToShowNotifications_NativePrompt();
  });
}

export function eventSubscriptionChange() {
  window.OneSignal.push(function () {
    window.OneSignal.on("subscriptionChange", function (isSubscribed) {
      window.OneSignal.getUserId(function (userId) {
        axios
          .post(`/api/onesignal/${userId}`)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    });
  });
}
