import React, { Component } from "react";

//Rate
import Rate from "rc-rate";

import axios from "axios";

//Response
import Response from "../../UI_Elements/Response";
import showResponse from "../../UI_Elements/Response/showResponse";

import defaultPhoto from "../../assets/default.png";

//Preloader
import Preloader from "../../UI_Elements/Preloader";

//Components
import Modal from "../../UI_Elements/Modal";
import Input from "../../UI_Elements/Form/Input";

class EndRateSession extends Component {
  constructor() {
    super();

    this.state = {
      endRateSessionFormSent: false,
      rate: 3,
      session: null,
    };

    this.ModalEndRateSession = React.createRef();
    this.comments = React.createRef();

    this.start = this.start.bind(this);
    this.END_RATE_SESSION = this.END_RATE_SESSION.bind(this);
  }

  END_RATE_SESSION(e) {
    e.preventDefault();

    //Info
    const rate = this.state.rate;
    const comments = this.comments.current.state.inputValue;

    const menteeUsername = this.state.session.menteeUsername;
    const mentorUsername = this.state.session.mentorUsername;
    const slug = this.state.session.slug;

    let sessionID = this.state.session._id ? this.state.session._id : null;

    this.setState({ endRateSessionFormSent: true });

    axios
      .post("api/end-rate", {
        rate,
        comments,
        menteeUsername,
        mentorUsername,
        slug,
        sessionID,
      })
      .then((res) => {
        this.setState({ endRateSessionFormSent: false });
        showResponse(
          "responseReviewSuccess",
          res.data.description,
          res.data.title,
        );
      })
      .catch((err) => {
        this.setState({ endRateSessionFormSent: false });
        showResponse(
          "responseReviewError",
          err.response.data.description,
          err.response.data.title,
        );
      });
  }

  //Setps
  start(session) {
    showResponse("endRateSession");
    this.setState({ session });
  }

  render() {
    const { session } = this.state;

    let currentUserSelected;
    if (session) {
      if (session.usr) {
        currentUserSelected = session.usr;
      } else {
        currentUserSelected = session.mentorObject;
      }
    }

    return (
      <React.Fragment>
        <Response
          id="endRateSession"
          type="success"
          btnToClose
          title="End & Rate your Session"
          description="You’re about to end your current session and you will need to book again if you want to connect with this mentor."
          btnText="End & Rate"
          btnAction="close"
          callback={() =>
            this.ModalEndRateSession.current._openCloseModal(true)
          }
        />

        <Modal
          ref={this.ModalEndRateSession}
          iconToClose
          className="ModalEndRateSession"
          title="End & Rate"
        >
          {session ? (
            <div>
              <span
                style={{
                  backgroundImage: `url(${currentUserSelected.userPhoto.trim() != "" ? currentUserSelected.userPhoto : defaultPhoto})`,
                }}
                className="photo"
              ></span>
              <h4>{session.titleListing}</h4>
              <p className="mentorName">
                {currentUserSelected.name} {currentUserSelected.surname}
              </p>

              <form onSubmit={this.END_RATE_SESSION} className="simple">
                <div className="rate">
                  <label htmlFor="">
                    How would you rate the session with your mentor?
                  </label>
                  <Rate
                    defaultValue={3}
                    onChange={(rate) => this.setState({ rate })}
                    style={{ fontSize: 24 }}
                    allowClear={false}
                  />
                </div>
                <Input
                  ref={this.comments}
                  textarea
                  label="Tell the community more about your experience"
                  placeholder="How was your experience with this Mentor?"
                  description="Your ratings and comments will be public."
                />

                <div className="actions flex">
                  {this.state.endRateSessionFormSent ? (
                    <Preloader />
                  ) : (
                    <button className="btn">submit</button>
                  )}
                  <p
                    onClick={() =>
                      this.ModalEndRateSession.current._openCloseModal(false)
                    }
                  >
                    cancel
                  </p>
                </div>
              </form>
            </div>
          ) : null}
        </Modal>

        {/* Response from modal */}
        <Response
          id="responseReviewSuccess"
          type="success"
          title=""
          description=""
          btnText="Go to profile"
          btnAction="/my-listings"
          callback={() =>
            this.ModalEndRateSession.current._openCloseModal(false)
          }
        />
        <Response
          id="responseReviewError"
          type="error"
          title=""
          description=""
          btnText="Close"
          btnAction="close"
          callback={() =>
            this.ModalEndRateSession.current._openCloseModal(false)
          }
        />
      </React.Fragment>
    );
  }
}

export default EndRateSession;
