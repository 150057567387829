import React, { Component } from "react";

import StarRatings from "../../UI_Elements/StarRatings";
import Timeline from "../../UI_Elements/Timeline";
import Separator from "../../UI_Elements/Separator";
import Nav from "../../components/Menu";
import Footer from "../../components/Footer";

import CardPublicProfile from "../../components/CardPublicProfile";

import { FiGlobe, FiMapPin } from "react-icons/fi";

import "./sass/PublicProfile.scss";
import defaultUser from "../../assets/default.png";

import axios from "axios";
import classnames from "classnames";

//Redux
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";

import Preloader from "../../UI_Elements/Preloader";

import Store from "../../redux/store";
import { UPDATE_USER } from "../../redux/actions";

class PublicProfile extends Component {
  constructor() {
    super();

    this.state = {
      publicUser: null,
      isLoading: true,
      errorStatus: null,
    };
  }

  async componentDidMount() {
    const { username } = this.props.match.params;
    await axios
      .post(`/api/get-public-profile/${username}`)
      .then((res) => {
        this.setState({ publicUser: res.data, isLoading: false });
      })
      .catch((error) => {
        this.setState({ errorStatus: error.response.status });
        console.log(error.response);
      });
  }

  render() {
    const { publicUser, isLoading, errorStatus } = this.state;
    const allUsersStatus = this.props.settings.allUsersStatus;
    const username = this.props.match.params.username;

    if (errorStatus) return <Redirect to="/404" />;
    if (isLoading) return <Preloader page />;

    return (
      <div id="PublicProfile">
        <Nav style="stylish" />

        <section className="header trlGlobal">
          <div className="content">
            <div className="Profile flex">
              <span
                style={{
                  backgroundImage: `url(${publicUser.userPhoto.trim() != "" ? publicUser.userPhoto : defaultUser})`,
                }}
                className={classnames({
                  photo: true,
                  on: allUsersStatus[username]
                    ? allUsersStatus[username].online
                    : false,
                })}
              ></span>
              <div>
                <main>
                  <h4>
                    {publicUser.name} {publicUser.surname}
                  </h4>
                  <p className="location">{publicUser.location}</p>
                </main>

                <p className="rating">
                  <span className="trl publicProfile ratingR">Rating:</span>
                  {publicUser.totReviews > 0 ? (
                    <StarRatings
                      rating={publicUser.rating / publicUser.totReviews}
                    />
                  ) : (
                    <small>
                      <i className="trl publicProfile noReviewsYet">
                        No reviews yet
                      </i>
                    </small>
                  )}
                </p>

                <button
                  onClick={() => {
                    document.copyTextToClipboard(
                      `${window.location.protocol}//${window.location.hostname}:${window.location.port}/${this.props.settings.publicProfile}/${username}`,
                    );
                    alert("Profile link copied to clipboard");
                  }}
                  className="btn white trl publicProfile shareProfile"
                >
                  Share profile
                </button>

                <div className="aboutMe">
                  <p className="trl publicProfile aboutMe">About me</p>
                  {publicUser.bio ? (
                    <p>{publicUser.bio}</p>
                  ) : (
                    <small className="trl publicProfile nothingToShow">
                      Nothing to show
                    </small>
                  )}
                </div>

                <ul className="flex">
                  {publicUser.location ? (
                    <li>
                      <FiMapPin size="15" /> {publicUser.location}
                    </li>
                  ) : null}
                  {/*<li><FiGlobe size="15" /> {publicUser.connectionPreferences.join(', ')}</li>*/}
                </ul>

                <div className="flex qualifications">
                  {publicUser.workExperience.length > 0 ? (
                    <Timeline
                      title="Work Experience"
                      items={publicUser.workExperience}
                    />
                  ) : null}
                  {publicUser.education.length > 0 ? (
                    <Timeline title="Education" items={publicUser.education} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <section className="listings">
            <div className="content">
              <Separator
                className="trl separator publicProfile publishedListings"
                title="Published listings"
              />

              <div className="listingMentorContainer flex">
                {publicUser.myListings.length > 0 ? (
                  publicUser.myListings.map((listing) => {
                    return (
                      <CardPublicProfile user={publicUser} listing={listing} />
                    );
                  })
                ) : (
                  <div style={{ margin: "11px", marginTop: 0 }}>
                    <p style={{ marginBottom: "15px" }}>
                      This user has not listings.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
        </section>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(PublicProfile);
