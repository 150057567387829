import React, { Component } from "react";

import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class WysiwygEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: props.value
        ? EditorState.createWithContent(convertFromRaw(props.value))
        : EditorState.createEmpty(),
    };

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange(editorState) {
    this.setState({ editorState });
  }

  render() {
    const { editorState } = this.state;
    const { label, error } = this.props;

    return (
      <div className="WysiwygEditor">
        <p
          className={`trl WysiwygEditor ${window.TranslateApp.clearString(label)}`}
        >
          {label}
        </p>
        <Editor
          toolbar={{
            options: ["inline", "list", "link", "emoji", "history"],
          }}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorContainer"
          onEditorStateChange={this.onEditorStateChange}
          placeholder={`Some ideas to help you get started:\n
Paste a link to a video from YouTube if you want to add a personalised intro or explanation of what other users may expect if they work with you.
Who are you?
What's your background?
What's your goal in Mentory?
How can you help others to become the best version of themselves?
Invite users to reach out if they need more info or would like to know more about you and your listing.`}
        />
        {error ? <span className="form error">{error}</span> : null}
      </div>
    );
  }
}

export default WysiwygEditor;
