import React, { Component } from "react";

import Nav from "../../components/Menu";
import Status from "./Status";

import classnames from "classnames";

import { MdFilterList, MdMoreHoriz, MdAttachFile } from "react-icons/md";

//Components
import Modal from "../../UI_Elements/Modal";
import Input from "../../UI_Elements/Form/Input";

//Response
import Response from "../../UI_Elements/Response";
import showResponse from "../../UI_Elements/Response/showResponse";

//Rate
import Rate from "rc-rate";

import "./sass/Chat.scss";

//Assets
import defaultPhoto from "../../assets/default.png";
import Emoji from "../../assets/Chat/emoji.svg";
import { FiSend, FiX, FiMove } from "react-icons/fi";

import {
  FiArrowLeft,
  FiPhone,
  FiFile,
  FiMinimize2,
  FiPower,
} from "react-icons/fi";

import { Socket } from "../../inc/SockeIO.cliente";

import axios from "axios";

//Redux
import { connect } from "react-redux";
import Store from "../../redux/store";
import { SETTINGS, NOTIFICATIONS } from "../../redux/actions";

//Contact
import Contact from "./Contact";

//Preloader
import Preloader from "../../UI_Elements/Preloader";

//Report user
import ReportUser from "../../components/ReportUser";

//Moment
import moment from "moment-timezone";

import { Link } from "react-router-dom";

import EndRateSession from "../../components/EndRateSession";

import CANCEL_REQUEST from "../../inc/CANCEL_REQUEST";

class Chat extends Component {
  constructor() {
    super();

    this.state = {
      openedFilter: false,
      openedEmojis: false,
      openedActionsToContact: false,

      currentMessage: "",
      allMessages: {},
      contactSelected: null,

      left: 0,

      rate: 3,

      //Video
      openVideo: false,
      toggleVideo: true,

      //Upload file
      uploadFile: null,

      //Filter chat + search
      filterStatus: [],
      searched: "",
    };

    this.handleClick = this.handleClick.bind(this);
    this.SEND_MESSAGE = this.SEND_MESSAGE.bind(this);
    this.START_CALL = this.START_CALL.bind(this);
    this.FINISH_CALL = this.FINISH_CALL.bind(this);
    this.openChat = this.openChat.bind(this);
    this.inputFileUploaded = this.inputFileUploaded.bind(this);
    this.filterStatus = this.filterStatus.bind(this);

    this.filter = React.createRef();
    this.emojis = React.createRef();
    this.actionsToContact = React.createRef();

    this.reportUserModal = React.createRef();
    this.inputFile = React.createRef();

    //End&Rate
    this.endRateSessionModal = React.createRef();
  }

  //Filter + Search
  filterStatus(status) {
    const { filterStatus } = this.state;

    var newStatus = [...filterStatus];

    if (newStatus.includes(status)) {
      newStatus = newStatus.filter((sl) => status != sl);
    } else {
      newStatus.push(status);
    }

    this.setState({ filterStatus: newStatus });
  }

  SEND_MESSAGE(e) {
    e.preventDefault();

    const { currentMessage, allMessages, contactSelected } = this.state;

    //Focus input again
    document.getElementById("InputMessage").focus();

    //Socket
    Socket.emit(
      "sendMessage",
      { msg: currentMessage, type: "text", contactSelected },
      (err, MESSAGE) => {
        if (err) {
          showResponse("sendMessageWarning", err, "");
          return this.setState({ currentMessage: "" });
        }

        var newAllMessages = allMessages;

        if (!newAllMessages[contactSelected.chatID])
          newAllMessages[contactSelected.chatID] = [];
        newAllMessages[contactSelected.chatID].push(MESSAGE);

        this.setState({ allMessages: newAllMessages, currentMessage: "" });

        this._scrollChat();
      },
    );
  }
  openChat(contact) {
    const { settings } = this.props;

    //Delete notification
    if (settings.notifications.includes(contact.chatID)) {
      Store.dispatch(NOTIFICATIONS(contact.chatID, "del"));
    } else if (settings.notifications.includes(contact.chatID)) {
      Store.dispatch(NOTIFICATIONS(contact.chatID, "del"));
    }

    Store.dispatch(SETTINGS("contactSelectedOnChat", contact));
    this.setState({ left: 0, contactSelected: contact });

    setTimeout(() => {
      this._scrollChat();
    }, 10);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    document.addEventListener("touchstart", this.handleClick, false);

    //Chat
    Socket.emit(
      "getAllMessagesFromMyContacts",
      this.props.user,
      (err, ALL_MESSAGES_FROM_DB) => {
        if (err) throw err;

        let newAllMessages = [];
        ALL_MESSAGES_FROM_DB.forEach(
          (messageObj) =>
            (newAllMessages[messageObj.chatIdentification] =
              messageObj.messages),
        );

        this.setState({ allMessages: newAllMessages });
      },
    );

    Socket.on("receive-msg", (NEW_MESSAGE) => {
      const { allMessages, contactSelected } = this.state;

      var newAllMessages = allMessages;

      if (!newAllMessages[NEW_MESSAGE.chatID])
        newAllMessages[NEW_MESSAGE.chatID] = [];
      newAllMessages[NEW_MESSAGE.chatID].push(NEW_MESSAGE);

      /******** NOTIFICATIONS *******/
      //Check if user has any contact selected
      if (!contactSelected) {
        Store.dispatch(NOTIFICATIONS(NEW_MESSAGE.chatID, "add"));
      } else {
        //this user has a contact selected. Now check if the contact select is the same the sender message
        if (
          contactSelected.menteeUsername == NEW_MESSAGE.who ||
          contactSelected.mentorUsername == NEW_MESSAGE.who
        ) {
        } else {
          Store.dispatch(NOTIFICATIONS(NEW_MESSAGE.chatID, "add"));
        }
      }

      this.setState({ allMessages: newAllMessages });

      this._scrollChat();
    });

    Socket.on("watch_update_user_model", (userObject) => {
      if (Store.getState().user.username != userObject.username) return;
      this.setState({ contactSelected: null });
    });

    //Update NOTIFICATIONS
    this.props.settings.notifications.forEach((nID) => {
      let findNotification = this.props.user.contacts.find(
        (c) => c.chatID == nID,
      );

      if (!findNotification) return Store.dispatch(NOTIFICATIONS(nID, "del"));
    });
  }
  _scrollChat() {
    const boxMessages = document.getElementById("messages");
    if (boxMessages) boxMessages.scrollTop = boxMessages.scrollHeight;
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
    document.removeEventListener("touchstart", this.handleClick, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.settings.userType != this.props.settings.userType) {
      this.setState({ contactSelected: null });
    }
  }

  handleClick(e) {
    if (this.state.contactSelected) {
      if (!this.emojis.contains(e.target))
        this.setState({ openedEmojis: false });
      if (!this.actionsToContact.contains(e.target))
        this.setState({ openedActionsToContact: false });
    }

    if (!this.filter.contains(e.target)) this.setState({ openedFilter: false });

    return;
  }

  FINISH_CALL() {
    document.querySelector("#VideoConference > div#iframe").innerHTML = "";
    this.setState({ openVideo: false });
  }

  async START_CALL(e, joinCall) {
    const { user } = this.props;
    const { contactSelected, allMessages } = this.state;

    if (window.confirm("This will start a call with this user.")) {
      await axios
        .post("/api/generate-video-conference", {
          loggedUsername: user.username,
          contactSelected,
        })
        .then((res) => {
          //Create video
          const video = document.createElement("iframe");
          video.src = `https://tokbox.com/embed/embed/ot-embed.js?embedId=${window.location.hostname == "mentory.me" ? "0a40b054-4403-4b86-9493-77ac62064dcc" : "6cb672c5-9a41-4a97-8c90-4b18e1100fad"}&room=${res.data}&iframe=true`;

          video.scrolling = "auto";
          video.allow = "microphone; camera";

          document.querySelector("#VideoConference > div#iframe").innerHTML =
            "";
          document
            .querySelector("#VideoConference > div#iframe")
            .appendChild(video);

          this.setState({ openVideo: true, openedActionsToContact: false });

          if (!joinCall) {
            //Socket - Send message to call
            Socket.emit(
              "sendMessage",
              { msg: "call", type: "call", contactSelected },
              (err, MESSAGE) => {
                var newAllMessages = allMessages;

                if (!newAllMessages[contactSelected.chatID])
                  newAllMessages[contactSelected.chatID] = [];
                newAllMessages[contactSelected.chatID].push(MESSAGE);

                this.setState({
                  allMessages: newAllMessages,
                  openedActionsToContact: false,
                });
              },
            );

            //Send oneSignal notificaiton
            const notificationToUser =
              user.username == contactSelected.menteeUsername
                ? contactSelected.mentorUsername
                : contactSelected.menteeUsername;
            axios.post(`/api/onesignal/notification/${notificationToUser}`, {
              type: "call",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          showResponse(
            "StartCallError",
            err.response.data.msg,
            err.response.data.title,
          );
        });
    }
  }

  inputFileUploaded(e) {
    const { contactSelected, allMessages } = this.state;
    const thisReact = this;

    let File = e.target.files[0];

    //check size
    if (File.size / 1000 > 4000)
      return window.alert("Error! You can't upload image above 4MG");

    //Type file
    if (
      File.type != "image/jpeg" &&
      File.type != "image/png" &&
      File.type != "image/gif" &&
      File.type != "application/zip"
    )
      return window.alert("Error! Only JPG, PNG, GIF or ZIP please");

    const formData = new FormData();
    formData.append("file", File);
    formData.append("contactSelected", JSON.stringify(contactSelected));

    //Progress
    var config = {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );

        if (percentCompleted == 100) {
          thisReact.setState({ uploadFile: null });
        } else {
          thisReact.setState({ uploadFile: percentCompleted });
        }
      },
    };

    axios
      .post("/api/chat/send-file", formData, config)
      .then((res) => {
        //Socket
        Socket.emit(
          "sendMessage",
          { msg: res.data, type: "file", contactSelected },
          (err, MESSAGE) => {
            if (err) {
              showResponse("sendMessageWarning", err, "");
              return console.error(err);
            }

            var newAllMessages = allMessages;

            if (!newAllMessages[contactSelected.chatID])
              newAllMessages[contactSelected.chatID] = [];
            newAllMessages[contactSelected.chatID].push(MESSAGE);

            this.setState({ allMessages: newAllMessages, currentMessage: "" });

            this._scrollChat();
          },
        );
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data);
      });
  }

  render() {
    const {
      openedFilter,
      openedEmojis,
      openedActionsToContact,
      currentMessage,
      left,
      contactSelected,
      allMessages,
      filterStatus,
      searched,
    } = this.state;
    const { user, settings } = this.props;

    var Emojis = [
      0x1f60a, 0x1f929, 0x1f911, 0x1f914, 0x1f910, 0x1f614, 0x1f913, 0x1f62e,
      0x1f622, 0x1f613, 0x1f621, 0x1f4a9, 0x1f648, 0x1f649, 0x1f64a, 0x1f49c,
      0x1f4af, 0x1f44c, 0x1f91e, 0x1f44d, 0x1f44e, 0x1f44f, 0x1f64c, 0x1f4aa,
      0x1f37b, 0x23f0, 0x1f389, 0x1f388, 0x1f3c6, 0x1f4a1, 0x1f4e9, 0x1f51c,
      0x1f51d, 0x2705, 0x274e, 0x1f197, 0x1f198,
    ];

    //Filter all contacts
    const mentorsContact = [];
    const menteesContact = [];

    user.contacts.forEach((c) => {
      //Search only Contacts on input
      if (searched.trim() != "" && searched) {
        let fullname = `${c.usr.name} ${c.usr.surname}`;
        if (!fullname.includes(searched)) return;
      }

      if (c.menteeUsername == user.username) menteesContact.push(c);
      if (c.mentorUsername == user.username) mentorsContact.push(c);
    });

    let validUserType;
    if (settings.currentUsername == user.username) {
      validUserType = settings.userType;
    } else {
      validUserType = "Mentee";
    }

    return (
      <div id="Chat">
        <Nav style="stylish" />

        <Response
          id="StartCallError"
          type="error"
          title=""
          description=""
          btnText="Ok"
          btnAction="close"
        />

        <Response
          id="sendMessageWarning"
          type="warning"
          title=""
          description=""
          btnText="Ok"
          btnAction="close"
        />

        <EndRateSession user={this.props.user} ref={this.endRateSessionModal} />

        <section>
          <div className="wrap">
            <div className="userPanel">
              <div className="main">
                <div>
                  <h4 className="trl connect mentoryConnectTitle">
                    Mentory Connect
                  </h4>

                  <div
                    onClick={() => this.setState({ openedFilter: true })}
                    ref={(node) => (this.filter = node)}
                    id="filter"
                  >
                    <MdFilterList size="20" />
                    <ul
                      className={classnames({
                        dropdownOpt: true,
                        active: openedFilter,
                      })}
                    >
                      <li
                        className={classnames({
                          active: filterStatus.includes("Active session"),
                          "trl connect status activeSession": true,
                        })}
                        onClick={() => this.filterStatus("Active session")}
                      >
                        Active sessions
                      </li>
                      <li
                        className={classnames({
                          active: filterStatus.includes("Response pending"),
                          "trl connect status responsePending": true,
                        })}
                        onClick={() => this.filterStatus("Response pending")}
                      >
                        Response pending
                      </li>
                      <li
                        className={classnames({
                          active: filterStatus.includes("Inbox"),
                          "trl connect status inbox": true,
                        })}
                        onClick={() => this.filterStatus("Inbox")}
                      >
                        Inbox
                      </li>
                    </ul>
                  </div>
                </div>

                <form className="simple smart" action="">
                  <Input
                    id="findContact"
                    onChange={(value) => this.setState({ searched: value })}
                    placeholder="Find contact"
                  />
                </form>
              </div>

              <article>
                {validUserType == "Mentor" ? (
                  mentorsContact.length != 0 || searched.trim() != "" ? (
                    <React.Fragment>
                      {
                        //INBOX
                        filterStatus.includes("Inbox") ||
                        filterStatus.length == 0 ? (
                          <React.Fragment>
                            <Status type="inbox" />
                            <br />
                            {mentorsContact.map((contact, i) => {
                              if (contact.status != "inbox") return;

                              return (
                                <Contact
                                  key={i}
                                  openChat={(contact) => this.openChat(contact)}
                                  contact={contact}
                                  contactSelected={contactSelected}
                                  allMessages={allMessages}
                                  i={i}
                                />
                              );
                            })}
                          </React.Fragment>
                        ) : null
                      }
                      {
                        //PENDING
                        filterStatus.includes("Response pending") ||
                        filterStatus.length == 0 ? (
                          <React.Fragment>
                            <Status type="pending" />
                            <br />
                            {mentorsContact.map((contact, i) => {
                              if (contact.status != "pending") return;

                              return (
                                <Contact
                                  key={i}
                                  openChat={(contact) => this.openChat(contact)}
                                  contact={contact}
                                  contactSelected={contactSelected}
                                  allMessages={allMessages}
                                  i={i}
                                />
                              );
                            })}
                          </React.Fragment>
                        ) : null
                      }
                      {
                        //ACTIVE
                        filterStatus.includes("Active session") ||
                        filterStatus.length == 0 ? (
                          <React.Fragment>
                            <Status type="active" />
                            <br />
                            {mentorsContact.map((contact, i) => {
                              if (contact.status != "active") return;

                              return (
                                <Contact
                                  key={i}
                                  openChat={(contact) => this.openChat(contact)}
                                  contact={contact}
                                  contactSelected={contactSelected}
                                  allMessages={allMessages}
                                  i={i}
                                />
                              );
                            })}
                          </React.Fragment>
                        ) : null
                      }
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <br />
                      <p className="trl connect notFoundUsers">
                        You need to book a session before connecting with other
                        users
                      </p>
                    </React.Fragment>
                  )
                ) : menteesContact.length != 0 || searched.trim() != "" ? (
                  <React.Fragment>
                    {
                      //INBOX
                      filterStatus.includes("Inbox") ||
                      filterStatus.length == 0 ? (
                        <React.Fragment>
                          <Status type="inbox" />
                          <br />
                          {menteesContact.map((contact, i) => {
                            if (contact.status != "inbox") return;

                            return (
                              <Contact
                                key={i}
                                openChat={(contact) => this.openChat(contact)}
                                contact={contact}
                                contactSelected={contactSelected}
                                allMessages={allMessages}
                                i={i}
                              />
                            );
                          })}
                        </React.Fragment>
                      ) : null
                    }
                    {
                      //PENDING
                      filterStatus.includes("Response pending") ||
                      filterStatus.length == 0 ? (
                        <React.Fragment>
                          <Status type="pending" />
                          <br />
                          {menteesContact.map((contact, i) => {
                            if (contact.status != "pending") return;

                            return (
                              <Contact
                                key={i}
                                openChat={(contact) => this.openChat(contact)}
                                contact={contact}
                                contactSelected={contactSelected}
                                allMessages={allMessages}
                                i={i}
                              />
                            );
                          })}
                        </React.Fragment>
                      ) : null
                    }
                    {
                      //ACTIVE
                      filterStatus.includes("Active session") ||
                      filterStatus.length == 0 ? (
                        <React.Fragment>
                          <Status type="active" />
                          <br />
                          {menteesContact.map((contact, i) => {
                            if (contact.status != "active") return;

                            return (
                              <Contact
                                key={i}
                                openChat={(contact) => this.openChat(contact)}
                                contact={contact}
                                contactSelected={contactSelected}
                                allMessages={allMessages}
                                i={i}
                              />
                            );
                          })}
                        </React.Fragment>
                      ) : null
                    }
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <br />
                    <p className="trl connect notFoundUsers">
                      You need to book a session before connecting with other
                      users
                    </p>
                  </React.Fragment>
                )}
              </article>
            </div>

            <div className="chat">
              {contactSelected ? (
                <div style={{ left }}>
                  <main>
                    <div className="first">
                      <span
                        onClick={() => {
                          Store.dispatch(
                            SETTINGS("contactSelectedOnChat", null),
                          );
                          this.setState({ left: 0, contactSelected: null });

                          this.setState({ left: "-100%" });
                        }}
                        className="back"
                      >
                        <FiArrowLeft size="25" />
                      </span>
                      <p>
                        {contactSelected.usr.name} {contactSelected.usr.surname}
                        {contactSelected.titleListing.trim() != "" ? (
                          <span>
                            {contactSelected.titleListing.trunc(50, true)}
                          </span>
                        ) : null}
                      </p>
                    </div>
                    <div className="last">
                      {contactSelected.status == "active" ? (
                        <React.Fragment>
                          <span
                            onClick={this.START_CALL}
                            className="start_call"
                          >
                            <FiPhone size="22" />
                          </span>
                          {validUserType == "Mentee" ? (
                            <span
                              onClick={() =>
                                this.endRateSessionModal.current.start(
                                  contactSelected,
                                )
                              }
                              className="finish_call"
                            >
                              <FiPower size="21" />
                            </span>
                          ) : null}
                        </React.Fragment>
                      ) : null}

                      <span
                        onClick={() =>
                          this.setState({ openedActionsToContact: true })
                        }
                        className="moreOptions"
                      >
                        <MdMoreHoriz size="25" />
                      </span>

                      <ul
                        ref={(node) => (this.actionsToContact = node)}
                        className={classnames({
                          dropdownOpt: true,
                          active:
                            openedActionsToContact && !this.state.openVideo,
                        })}
                      >
                        {/* ACTIONS FOR USERS */}
                        {contactSelected.status == "inbox" ? (
                          validUserType == "Mentor" ? (
                            <React.Fragment>
                              {/* <li>Delete conversation</li> */}
                              <li
                                className="trl connect reportUser"
                                onClick={() =>
                                  this.reportUserModal.current._openCloseModal(
                                    true,
                                  )
                                }
                              >
                                Report user
                              </li>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <li className="link">
                                <Link
                                  className="trl connect bookSession"
                                  to={`/${settings.publicProfile}/${contactSelected.mentorUsername}`}
                                >
                                  Book a session
                                </Link>
                              </li>
                              {/* <li>Delete conversation</li> */}
                              <li
                                className="trl connect reportUser"
                                onClick={() =>
                                  this.reportUserModal.current._openCloseModal(
                                    true,
                                  )
                                }
                              >
                                Report user
                              </li>
                            </React.Fragment>
                          )
                        ) : null}

                        {contactSelected.status == "pending" ? (
                          validUserType == "Mentor" ? (
                            <React.Fragment>
                              <li className="link">
                                <Link
                                  className="trl connect manageSessions"
                                  to="/sessions"
                                >
                                  Manage request
                                </Link>
                              </li>
                              {/* <li>Delete conversation</li> */}
                              <li
                                className="trl connect reportUser"
                                onClick={() =>
                                  this.reportUserModal.current._openCloseModal(
                                    true,
                                  )
                                }
                              >
                                Report user
                              </li>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <li className="link">
                                <Link
                                  className="trl connect editRequest"
                                  to="/sessions"
                                >
                                  Edit request
                                </Link>
                              </li>
                              <li
                                className="trl connect cancelRequest"
                                onClick={() =>
                                  CANCEL_REQUEST(
                                    contactSelected.mentorUsername,
                                    contactSelected.menteeUsername,
                                  )
                                }
                              >
                                Cancel request
                              </li>
                              {/*<li>Delete conversation</li>*/}
                              <li
                                className="trl connect reportUser"
                                onClick={() =>
                                  this.reportUserModal.current._openCloseModal(
                                    true,
                                  )
                                }
                              >
                                Report user
                              </li>
                            </React.Fragment>
                          )
                        ) : null}

                        {contactSelected.status == "active" ? (
                          validUserType == "Mentor" ? (
                            <React.Fragment>
                              <li
                                className="trl connect startCall"
                                onClick={this.START_CALL}
                              >
                                Start call
                              </li>
                              <li
                                className="trl connect reportUser"
                                onClick={() =>
                                  this.reportUserModal.current._openCloseModal(
                                    true,
                                  )
                                }
                              >
                                Report user
                              </li>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <li
                                className="trl connect startCall"
                                onClick={this.START_CALL}
                              >
                                Start call
                              </li>
                              <li
                                className="trl connect endRate"
                                onClick={() =>
                                  this.endRateSessionModal.current.start(
                                    contactSelected,
                                  )
                                }
                              >
                                End & rate
                              </li>
                              <li
                                className="trl connect reportUser"
                                onClick={() =>
                                  this.reportUserModal.current._openCloseModal(
                                    true,
                                  )
                                }
                              >
                                Report user
                              </li>
                            </React.Fragment>
                          )
                        ) : null}
                      </ul>
                    </div>
                  </main>

                  <div id="messages">
                    {allMessages[contactSelected.chatID] &&
                    allMessages[contactSelected.chatID].length > 0 ? (
                      allMessages[contactSelected.chatID].map(
                        (MESSAGE_OBJECT, i) => {
                          return (
                            <p
                              key={i}
                              className={`msg ${MESSAGE_OBJECT.who == this.props.user.username ? "i" : "contact"}`}
                            >
                              {MESSAGE_OBJECT.type == "file" ? (
                                <span className="spanMessage file">
                                  <a href={MESSAGE_OBJECT.text} target="_blank">
                                    <FiFile size="15" />{" "}
                                    {MESSAGE_OBJECT.text.substring(
                                      MESSAGE_OBJECT.text.length,
                                      MESSAGE_OBJECT.text.lastIndexOf("_") + 1,
                                    )}
                                  </a>
                                </span>
                              ) : null}
                              {MESSAGE_OBJECT.type == "text" ? (
                                <span
                                  className="spanMessage"
                                  dangerouslySetInnerHTML={{
                                    __html: MESSAGE_OBJECT.text,
                                  }}
                                ></span>
                              ) : null}
                              {MESSAGE_OBJECT.type == "call" ? (
                                <span className="spanMessage">
                                  &#128222; Call started.{" "}
                                  <a
                                    className="primaryLink"
                                    href="#"
                                    onClick={(e) => this.START_CALL(e, true)}
                                  >
                                    Click here to join.
                                  </a>
                                </span>
                              ) : null}
                              <span className="spanDate">
                                {moment(MESSAGE_OBJECT.date).format("LT")}
                              </span>
                            </p>
                          );
                        },
                      )
                    ) : (
                      <p className="trl connect noMessages">
                        Your conversation will show here when you message this
                        user.
                      </p>
                    )}

                    {
                      //Upload file?
                      this.state.uploadFile ? (
                        <progress
                          value={this.state.uploadFile}
                          max="100"
                        ></progress>
                      ) : null
                    }
                  </div>

                  <form autoComplete="off" onSubmit={this.SEND_MESSAGE}>
                    <div className="input">
                      <div>
                        <div
                          onClick={() => this.setState({ openedEmojis: true })}
                          id="emojis"
                        >
                          <img src={Emoji} alt="Emoji" />
                          <ul
                            ref={(node) => (this.emojis = node)}
                            className={classnames({
                              active: openedEmojis,
                            })}
                          >
                            {Emojis.map((emoji, i) => {
                              return (
                                <li
                                  onClick={(e) => {
                                    document
                                      .getElementById("InputMessage")
                                      .focus();
                                    this.setState({
                                      currentMessage:
                                        currentMessage + e.target.innerHTML,
                                    });
                                  }}
                                  key={i}
                                >
                                  {String.fromCodePoint(emoji)}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <input
                          autoComplete="false"
                          id="InputMessage"
                          onChange={(e) =>
                            this.setState({ currentMessage: e.target.value })
                          }
                          value={currentMessage}
                          placeholder="Write a message..."
                          type="text"
                        />
                      </div>

                      <div>
                        <div
                          onClick={() => {
                            this.inputFile.current.click();
                            document.getElementById("InputMessage").focus();
                          }}
                          id="file"
                        >
                          <MdAttachFile size="25" />
                          <input
                            onChange={this.inputFileUploaded}
                            ref={this.inputFile}
                            type="file"
                          />
                        </div>

                        <button type="submit">
                          <FiSend size="20" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="nothingSelected">
                  <p className="trl connect nothingSelected">
                    &#128072; Select an user to get started
                  </p>
                </div>
              )}
            </div>
          </div>
        </section>

        <div
          id="VideoConference"
          className={classnames({
            opened: this.state.openVideo,
            desktopFull: window.innerWidth > 700 && this.state.toggleVideo,
            desktopSmall: window.innerWidth > 700 && !this.state.toggleVideo,
            mobileFull: window.innerWidth <= 700 && this.state.toggleVideo,
            mobileSmall: window.innerWidth <= 700 && !this.state.toggleVideo,
          })}
        >
          <div id="actions">
            <button
              id="toggleVideo"
              onClick={() =>
                this.setState({ toggleVideo: !this.state.toggleVideo })
              }
            >
              <FiMinimize2 size="14" />
            </button>
            <button id="finishCall" onClick={this.FINISH_CALL}>
              <FiX size="18" />
            </button>
          </div>
          <div id="iframe"></div>
        </div>

        {contactSelected ? (
          <ReportUser
            userPhoto={contactSelected.usr.userPhoto}
            name={contactSelected.usr.name}
            surname={contactSelected.usr.surname}
            username={contactSelected.usr.username}
            titleListing={contactSelected.titleListing}
            refModal={this.reportUserModal}
          />
        ) : (
          <ReportUser refModal={this.reportUserModal} />
        )}
      </div>
    );
  }
}

const mapToStateProps = (state) => {
  return state;
};
export default connect(mapToStateProps)(Chat);
