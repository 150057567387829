import axios from "axios";
import Store from "../redux/store";
import { UPDATE_USER } from "../redux/actions";

export default (slug, callback) => {
  if (
    window.confirm("Are you sure you want to add this listing as favorite?")
  ) {
    axios
      .post(`/api/favourite/${slug}`)
      .then((res) => {
        Store.dispatch(UPDATE_USER(res.data));

        if (callback) callback(res);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        if (callback) callback(err);
      });
  }
};
