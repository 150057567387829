import axios from "axios";

export default async (myCurrency, mentorCurrency, listingPaid) => {
  if (myCurrency.trim() != "") {
    if (mentorCurrency != myCurrency && listingPaid) {
      //Get conversion currencies
      const res = await axios.post("/api/openexchangerates", {
        base: mentorCurrency,
        to: myCurrency,
      });

      if (res.data.rate) {
        return res.data.rate;
      } else {
        console.log(res);
        return false;
      }
    }
  }
};
