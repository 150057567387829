import React, { Component } from "react";

import "./sass/ActiveSessionMentee.scss";

import { MdMoreVert } from "react-icons/md";
import defaultPhoto from "../../../assets/default.png";

import classnames from "classnames";

import { Link } from "react-router-dom";

import Money from "../../../inc/Money";

import { connect } from "react-redux";

import moment from "moment-timezone";

class ActiveSessionMentee extends Component {
  constructor() {
    super();

    this.state = {
      openedSettingsMenu: false,
    };

    this.handleClick = this.handleClick.bind(this);

    this.containerSettings = React.createRef();
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick(e) {
    if (this.containerSettings.contains(e.target)) return;

    this.setState({ openedSettingsMenu: false });
  }

  render() {
    const { openedSettingsMenu } = this.state;
    const { session, settings } = this.props;

    return (
      <div className="ActiveSessionMentee trlGlobal">
        <div
          ref={(node) => (this.containerSettings = node)}
          onClick={() => this.setState({ openedSettingsMenu: true })}
          className="settings"
        >
          <MdMoreVert size="22" />
          <ul
            className={classnames({
              dropdownOpt: true,
              active: openedSettingsMenu ? true : false,
            })}
          >
            <li className="link">
              <Link
                className="trl ActiveSessionMentee contactMentor"
                to="/connect"
              >
                Contact mentor
              </Link>
            </li>
            <li className="link">
              <Link
                className="trñ ActiveSessionMentee viewProfile"
                to={`/${settings.publicProfile}/${session.mentorObject.username}`}
              >
                View profile
              </Link>
            </li>
            <li className="link">
              <Link
                className="trl ActiveSessionMentee viewListing"
                to={`/listing/${session.slug}`}
              >
                View listing
              </Link>
            </li>
            <li
              className="trl ActiveSessionMentee openDispute"
              onClick={this.props.onClickOpenDispute}
            >
              Open a dispute
            </li>
            <li
              className="trl ActiveSessionMentee reportUser"
              onClick={this.props.onClickReportUser}
            >
              Report user
            </li>
          </ul>
        </div>
        <span
          className="photo"
          style={{
            backgroundImage: `url("${session.mentorObject.userPhoto.trim() != "" ? session.mentorObject.userPhoto : defaultPhoto}")`,
          }}
        ></span>
        <div>
          <h4>
            {session.mentorObject.name} {session.mentorObject.surname}
          </h4>
          <p className="description">{session.titleListing}</p>
          <p className="received">
            <span className="trl ActiveSessionMentee received">Received</span>{" "}
            {moment(session.whenRequestedSession).format("DD MMMM YYYY")}
          </p>
          <p className="youWillMake">
            {parseInt(session.price.mentee) > 0 ? (
              <React.Fragment>
                <span className="trl ActiveSessionMentee youPaidSpan">
                  You paid
                </span>{" "}
                <Money
                  value={session.price.mentee / 100}
                  currency={this.props.user.currency}
                />
              </React.Fragment>
            ) : (
              <span className="trl ActiveSessionMentee free">FREE</span>
            )}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(ActiveSessionMentee);
