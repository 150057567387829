import React, { Component } from "react";

class Status extends Component {
  render() {
    const Status = {};
    const { type } = this.props;

    let titleClass;

    if (type === "active") {
      Status.background = "#4663F6";
      Status.title = "Active sessions";
      titleClass = "activeSession";
    } else if (type == "pending") {
      Status.background = "#FF9721";
      Status.title = "Response pending";
      titleClass = "responsePending";
    } else if (type == "inbox") {
      Status.background = "#6B7C93";
      Status.title = "Inbox";
      titleClass = "inbox";
    } else {
      alert('Error! You are 3 types available: "active", "pending", "inbox"');
      throw Error(
        'Error! You are 3 types available: "active", "pending", "inbox"',
      );
    }

    return (
      <span
        style={{ background: Status.background }}
        className={`trl connect status ${titleClass}`}
      >
        {Status.title}
      </span>
    );
  }
}

export default Status;
