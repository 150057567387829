import React, { Component } from "react";

import PlacesAutocomplete from "react-places-autocomplete";
import classnames from "classnames";

class Location extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: props.value ? props.value : "",
      addressFromGoogleMapsPlace: props.value ? props.value : null,
    };

    this._validateLocation = this._validateLocation.bind(this);
  }

  _validateLocation() {
    const { address, addressFromGoogleMapsPlace } = this.state;
    const { value } = this.props;

    if (value == address) return true;
    if (addressFromGoogleMapsPlace) return true;

    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ address: nextProps.value });
    }
  }

  render() {
    const { className, label, icon, description, error, placeholder, id } =
      this.props;

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={(address) =>
          this.setState({ address, addressFromGoogleMapsPlace: null })
        }
        onSelect={(address) => {
          if (this.props.onChange) this.props.onChange(address);
          this.setState({ address, addressFromGoogleMapsPlace: address });
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            id={id ? id : null}
            className={classnames({
              "Input Location": true,
              [className]: className ? true : false,
            })}
          >
            {label ? (
              <label
                className={
                  label
                    ? `trl label ${window.TranslateApp.clearString(label)}`
                    : ""
                }
              >
                {label}
              </label>
            ) : null}

            <p
              className={classnames({
                flex: true,
              })}
            >
              <input
                {...getInputProps({
                  placeholder,
                  className: `trl location-search-input ${id}`,
                })}
                value={this.state.address}
              />

              {icon ? icon : null}
            </p>
            {description ? <small>{description}</small> : null}
            {error ? <span className="form error">{error}</span> : null}

            <ul>
              {loading && <li>loading...</li>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";

                return (
                  <li
                    onClick={(e) => this.selectValue(e)}
                    key={suggestion.description}
                    {...getSuggestionItemProps(suggestion, { className })}
                  >
                    {suggestion.description}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default Location;
