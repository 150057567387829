import axios from "axios";

import showResponse from "../../UI_Elements/Response/showResponse";

export default (successModaID, errorModalID, email) => {
  if (window.confirm("Are you would like reset your password?")) {
    axios
      .post(`/api/reset-password`, { email })
      .then((res) => {
        console.log(res);
        showResponse(successModaID);
      })
      .catch((err) => {
        console.log(err);
        showResponse(errorModalID, err.response.data);
      });
  }
};
