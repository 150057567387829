import React, { Component } from "react";

import "./sass/CardMentor.scss";

//Assets
import defaultPhoto from "../../assets/default.png";
import classnames from "classnames";

//Icons
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FiGlobe, FiMapPin, FiSmartphone } from "react-icons/fi";

import StarRatings from "../../UI_Elements/StarRatings";

import { connect } from "react-redux";
import ADD_FAVOURITE from "../../inc/ADD_FAVOURITE";

import ShowPrice from "../../inc/ShowPrice";

class CardMentor extends Component {
  constructor() {
    super();

    this.state = {
      favourites: false,
    };
  }

  render() {
    const { info } = this.props;

    return (
      <div
        className={classnames({
          CardMentor: true,
          trlGlobal: true,
          on: this.props.settings.allUsersStatus[info.mentor.username]
            ? this.props.settings.allUsersStatus[info.mentor.username].online
            : false,
        })}
      >
        <div className="flex mentorInfo">
          <span className="favourite">
            {this.props.settings.userLogged && this.props.user ? (
              this.props.user.myFavourites.find(
                (listingFavourite) =>
                  listingFavourite.slug == info.listing.slug,
              ) ? (
                <FaHeart style={{ color: "#4663F6" }} size="20" />
              ) : (
                <FaRegHeart
                  onClick={() => ADD_FAVOURITE(info.listing.slug)}
                  size="20"
                />
              )
            ) : null}
          </span>

          <div className="left">
            <span
              style={{
                backgroundImage: `url("${info.mentor.userPhoto.trim() != "" ? info.mentor.userPhoto : defaultPhoto}")`,
              }}
              className="photo"
            ></span>
            <ShowPrice
              pricePerHour={info.listing.pricePerHour}
              currency={info.listing.currency}
            />
          </div>

          <div className="right">
            <p className="name">
              {info.mentor.name} {info.mentor.surname}
            </p>

            {info.listing.rating > 0 ? (
              <StarRatings
                rating={info.listing.rating / info.listing.reviews.length}
              />
            ) : (
              <small>
                <i className="trl CardMentor noReviewsYet">No reviews yet</i>
              </small>
            )}

            <ul>
              <li>
                <FiMapPin size="15" />{" "}
                {info.listing.location.length > 20
                  ? info.listing.location.substring(0, 20) + "..."
                  : info.listing.location}
              </li>
              <li>
                <FiGlobe size="15" /> {info.listing.languages.join(", ")}
              </li>
              <li>
                <FiSmartphone size="17" />{" "}
                {info.listing.connectionPreferences.join(", ")}
              </li>
            </ul>
          </div>
        </div>

        <div className="listingInfo">
          <h4>{info.listing.title}</h4>
          <div className="flex">
            {info.listing.services.map((s, i) => {
              if (!s.active) return;
              return (
                <a key={i} className="tag" href="">
                  {s.title}
                </a>
              );
            })}
          </div>

          <a href={`/listing/${info.listing.slug}`}>
            <button className="btn trl CardMentor viewListing">
              view listing
            </button>
          </a>
        </div>
      </div>
    );
  }
}

const mapToStateProps = (state) => {
  return state;
};
export default connect(mapToStateProps)(CardMentor);
