export default (object, active, thisReact) => {
  //Listing title
  if (!object.listingTitleIsValid && active.listingTitleIsValid === 1) {
    thisReact.setState({
      errorListingTitle:
        "The name must begin with a capital letter and must contain at least 10 characters and a maximum of 100.",
    });
  } else thisReact.setState({ errorListingTitle: "" });

  //Location
  if (!object.locationIsValid && active.locationIsValid === 1) {
    thisReact.setState({ errorLocation: "Select a valid location" });
  } else thisReact.setState({ errorLocation: "" });

  //Listing bio
  if (!object.listingBioIsValid && active.listingBioIsValid === 1) {
    thisReact.setState({
      errorListingBio:
        "Your description must begin with a capital letter and must containg at least 350 characters and a maximum of 2000.",
    });
  } else thisReact.setState({ errorListingBio: "" });

  //Language
  if (!object.languageIsValid && active.languageIsValid === 1) {
    thisReact.setState({ errorLanguage: "Select at least one language" });
  } else thisReact.setState({ errorLanguage: "" });

  //Connection preferences
  if (
    !object.connectionPreferencesIsValid &&
    active.connectionPreferencesIsValid === 1
  ) {
    thisReact.setState({
      errorConnectionPreferences: "Select at least one connection preference",
    });
  } else thisReact.setState({ errorConnectionPreferences: "" });

  //Linkedin profile URL
  if (!object.linkedinProfileIsValid && active.linkedinProfileIsValid === 1) {
    thisReact.setState({
      errorLinkedinProfile:
        "The link is not valid. The link needs to be a valid LinkedIn URL.",
    });
  } else thisReact.setState({ errorLinkedinProfile: "" });

  //Services
  if (!object.servicesIsValid && active.servicesIsValid === 1) {
    thisReact.setState({
      errorServices: "Select at least one service and one duration value",
    });
  } else thisReact.setState({ errorServices: "" });

  //Photo
  if (!object.userPhotoIsValid && active.userPhotoIsValid === 1) {
    thisReact.setState({ errorUserPhoto: "Please, upload a valid photo" });
  } else thisReact.setState({ errorUserPhoto: "" });

  //pricePerHour
  if (!object.pricePerHourIsValid && active.pricePerHourIsValid === 1) {
    thisReact.setState({ errorPricePerHour: "This value is not valid." });
  } else thisReact.setState({ errorPricePerHour: "" });
};
